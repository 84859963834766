import ExcelJS from "exceljs";
import { FaFilePdf } from "react-icons/fa6";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut, Pie } from "react-chartjs-2";
import { API_BASE_URL } from "../utils/apiConfig";

import { SiMicrosoftexcel } from "react-icons/si";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import themes from "../utils/themes";
import Logo from "../assets/Logo.png";
const RepaymentSchedule = () => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const initialFormData = {
    loanAmount: "",
    loanTenure: "",
    interestRate: "",
    emiDate: "",
    loanStartDate: "",
  };
  const currentTheme = useSelector((state) => state.theme);
  const docThemes = themes[currentTheme].docs;

  const [formData, setFormData] = useState(initialFormData);
  const [interest, setInterest] = useState("");
  const [emiData, setEmiData] = useState("");
  const [showFAQ, setShowFAQ] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const formatDate = (dateString) => {
    const options = {
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInterestChange = (e) => {
    const { name, value } = e.target;
    if (/^(?:\d{1,2}|100)$/.test(value) || value === "") {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }
  };

  const handleEmiDateChange = (e) => {
    const { name, value } = e.target;
    // Validate that the value is a number between 1 and 31
    if (/^\d+$/.test(value) && parseInt(value, 10) >= 1 && parseInt(value, 10) <= 31) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      // Display an error or prevent setting the value
      console.error("Invalid emiDate. Must be a number between 1 and 31.");
    }
  };
  


  const calculateRepaymentSchedule = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/calculate/repayment-schedule`,
        formData
      );
      setEmiData(response.data);
      setError("");
    } catch (error) {
      console.error("API error:", error);
      setError(error.response.data.error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadPDF = async () => {
    await downloadPDF();
  };

  const handleDownloadExcel = async () => {
    await downloadXLSX();
  };

  const downloadPDF = () => {
    if (!emiData || emiData.length === 0) {
      console.error("No data available for PDF generation.");
      return;
    }

    const pdf = new jsPDF();

    pdf.setFontSize(20);
    pdf.setTextColor(docThemes.title);

    const appNameX = 88;
    const appNameY = 25;
    const logoX = 68;
    const logoY = 12;
    const logoWidth = 18;
    const logoHeight = 18;

    pdf.text("LoanHub", appNameX, appNameY);
    pdf.addImage(Logo, "PNG", logoX, logoY, logoWidth, logoHeight);

    pdf.setTextColor(docThemes.text);

    const textBelowX = 60;
    const textBelowY = 40;

    pdf.text("Loan Repayment Schedule", textBelowX, textBelowY);

    const headers = [
      "Due Date",
      "Status",
      "EMI (INR)",
      "Interest (INR)",
      "Principal (INR)",
    ];

    const data = emiData.repaymentSchedule.map((emi) => [
      formatDate(emi.dueDate),
      emi.status,
      `${parseFloat(emi.amount).toFixed(0)}`,
      `${parseFloat(emi.interest).toFixed(0)}`,
      `${parseFloat(emi.principalPortion).toFixed(0)}`,
    ]);

    pdf.autoTable({
      head: [headers],
      body: data,
      startY: 50,
      theme: "grid",

      headStyles: {
        fontSize: 14,
        fillColor: docThemes.background,
        textColor: docThemes.heading,
      },

      styles: {
        fontSize: 14,
        textColor: docThemes.text,
        fillColor: docThemes.secondaryBackground,
        cellPadding: { top: 2, right: 5, bottom: 2, left: 5 },
        valign: "middle",
        halign: "center",
        cellBorder: "none",
      },

      margin: { top: 20, bottom: 20, left: 10, right: 10 },

      rowPageBreak: "avoid",
    });

    pdf.save("Loan Repayment Schedule.pdf");
  };

  const downloadXLSX = async () => {
    if (!emiData || emiData.length === 0) {
      console.error("No data available for Excel generation.");
      return;
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Repayment Schedule");

    const headerRow = worksheet.addRow([
      "Due Date",
      "Status",
      "EMI (INR)",
      "Interest (INR)",
      "Principal (INR)",
    ]);

    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: docThemes.background },
      };
      cell.font = { color: { argb: docThemes.heading }, bold: true };
    });

    emiData.repaymentSchedule.forEach((emi) => {
      worksheet.addRow([
        formatDate(emi.dueDate),
        emi.status,
        parseFloat(emi.amount).toFixed(0),
        parseFloat(emi.interest).toFixed(0),
        parseFloat(emi.principalPortion).toFixed(0),
      ]);
    });

    worksheet.eachRow({ includeEmpty: false }, (row) => {
      row.eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: docThemes.secondaryBackground },
        };
        cell.font = { color: { argb: docThemes.text } };
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const fileName = "Repayment Schedule.xlsx";

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  const totalInterest = emiData.totalInterest;
  const totalPrincipal = emiData.totalPrincipal;

  const data = {
    labels: ["Total Interest", "Total Principal"],
    datasets: [
      {
        data: [totalInterest, totalPrincipal],

        backgroundColor: [
          `${themes[currentTheme].charts.accent1}`,
          `${themes[currentTheme].charts.accent2}`,
          `${themes[currentTheme].charts.accent3}`,
          `${themes[currentTheme].charts.accent4}`,
          `${themes[currentTheme].charts.accent5}`,
          `${themes[currentTheme].charts.accent6}`,
          `${themes[currentTheme].charts.accent7}`,
        ],
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {},
      },
      legend: {
        display: false,
        labels: {
          display: false,
        },
      },
    },
  };

  const toggleFAQ = () => {
    setShowFAQ(!showFAQ);
  };
  const inputBoxStyle = `${themes[currentTheme].colors.input} ${themes[currentTheme].colors.inputText} mt-1 p-2 w-full rounded-md focus:outline-none focus:border-blue-500`;
  return (
    <div
      className={`h-screen  overflow-y-auto  p-5 ${themes[currentTheme].colors.pageBackground}`}
    >
      <h2 className="text-2xl font-bold mb-4">Repayment Schedule</h2>
      <div
        className={`mx-auto my-8 p-6 ${themes[currentTheme].colors.card} rounded-md shadow-md max-w-2xl`}
      >
        <div className="mb-4">
          <label className="block text-sm font-medium ">Loan Amount</label>
          <input
            type="number"
            name="loanAmount"
            value={formData.loanAmount}
            onChange={handleChange}
            required
            className={inputBoxStyle}
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium ">Loan Tenure</label>
          <input
            type="number"
            name="loanTenure"
            value={formData.loanTenure}
            onChange={handleChange}
            required
            className={inputBoxStyle}
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium ">Interest Rate</label>
          <input
            type="number"
            name="interestRate"
            value={formData.interestRate}
            onChange={handleInterestChange}
            required
            className={inputBoxStyle}
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium ">EMI Date</label>
          <input
            type="number"
            name="emiDate"
            value={formData.emiDate}
            onChange={handleEmiDateChange}
            required
            className={inputBoxStyle}
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium ">Loan Start Date</label>
          <input
            type="date"
            name="loanStartDate"
            value={formData.loanStartDate}
            onChange={handleChange}
            required
            className={inputBoxStyle}
          />
        </div>

        <div className="flex justify-center">
          <button
            onClick={calculateRepaymentSchedule}
            className={`${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonText} w-40 font-semibold py-2 px-4 rounded-md ${themes[currentTheme].colors.buttonHover} focus:outline-none`}
          >
            Calculate
          </button>
        </div>

        {error && (
          <p
            className={`${themes[currentTheme].colors.errorText} font-semibold mb-4`}
          >
            {error}
          </p>
        )}

        {emiData && (
          <div className="mt-4">
            <div className="grid grid-cols-3 my-6">
              <div className="flex flex-col justify-center">
                <div>
                  EMI: ₹{" "}
                  <span className="font-semibold">{emiData.monthlyEMI}</span>
                </div>
                <div>
                  Loan Amount: ₹{" "}
                  <span className="font-semibold">
                    {emiData.totalPrincipal}
                  </span>
                </div>
                <div>
                  Interest: ₹{" "}
                  <span className="font-semibold">{emiData.totalInterest}</span>
                </div>
                <div>
                  Total: ₹{" "}
                  <span className="font-semibold">{emiData.totalAmount}</span>
                </div>
              </div>

              <div className="h-40 flex  justify-center items-center">
                <Doughnut data={data} options={options} />
              </div>

              <div className="flex flex-col gap-2 justify-center items-center">
                <button
                  onClick={handleDownloadPDF}
                  className={`${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonText} font-semibold py-2 px-4 rounded-md ${themes[currentTheme].colors.buttonHover} focus:outline-none flex gap-2`}
                >
                  <FaFilePdf fontSize={24} />
                  Download
                </button>
                <button
                  onClick={handleDownloadExcel}
                  className={`${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonText} font-semibold py-2 px-4 rounded-md ${themes[currentTheme].colors.buttonHover} focus:outline-none flex gap-2`}
                >
                  <SiMicrosoftexcel fontSize={24} />
                  Download
                </button>
              </div>
            </div>

            <h2 className="text-2xl underline-offset-1 underline text-center font-bold mt-3">
              Loan Repayment Schedule
            </h2>

            <table className="mt-2 min-w-max text-center">
              <thead>
                <tr>
                  <th className="p-3">Due Date</th>
                  <th className="p-3">Status</th>
                  <th className="p-3">EMI</th>
                  <th className="p-3">Interest</th>
                  <th className="p-3">Principal</th>
                  <th className="p-3">OutStanding Balance</th>
                </tr>
              </thead>
              <tbody>
                {emiData.repaymentSchedule.map((emi, index) => (
                  <tr key={index}>
                    <td className="p-3">{formatDate(emi.dueDate)}</td>
                    <td className="p-3">
                      {emi.status.charAt(0).toUpperCase() + emi.status.slice(1)}
                    </td>
                    <td className="p-3">
                      ₹ {parseFloat(emi.amount).toFixed(0)}
                    </td>
                    <td className="p-3">
                      ₹ {parseFloat(emi.interest).toFixed(0)}
                    </td>
                    <td className="p-3">
                      ₹ {parseFloat(emi.principalPortion).toFixed(0)}
                    </td>
                    <td className="p-3">
                      ₹ {parseFloat(emi.outstandingPrincipal).toFixed(0)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className="mt-8 border-t pt-4">
          <button
            onClick={toggleFAQ}
            className={`${themes[currentTheme].colors.accentText} hover:underline focus:outline-none`}
          >
            {showFAQ ? "Hide FAQ" : "Show FAQ"}
          </button>

          {showFAQ && (
            <div className="mt-4">
              <h3 className="text-xl font-bold mb-2">
                Loan Repayment Schedule FAQs
              </h3>
              <p>
                <strong>Q: </strong> What is the loan repayment schedule?
                <br />
                <strong>A: </strong> The loan repayment schedule outlines the
                dates and amounts of each installment (EMI) that a borrower is
                required to pay to repay the loan over its term.
              </p>
              <p>
                <strong>Q: </strong> How is the monthly EMI calculated?
                <br />
                <strong>A: </strong> The monthly EMI is calculated based on the
                loan amount, interest rate, and loan tenure. It includes both
                principal and interest components.
              </p>
              <p>
                <strong>Q: </strong> Can I change the repayment schedule of my
                loan?
                <br />
                <strong>A: </strong> In most cases, the repayment schedule is
                fixed. However, some lenders may offer options for restructuring
                or modifying the schedule under specific circumstances.
              </p>
              <p>
                <strong>Q: </strong> What happens if I miss an EMI payment?
                <br />
                <strong>A: </strong> Missing an EMI payment may result in late
                fees or penalties. It's important to communicate with your
                lender and discuss any challenges you face to explore possible
                solutions.
              </p>
              <p>
                <strong>Q: </strong> Can I make prepayments or pay off the loan
                early?
                <br />
                <strong>A: </strong> Many loans allow for prepayments or early
                repayment. However, check your loan agreement for any prepayment
                penalties or conditions associated with early repayment.
              </p>
              <p>
                <strong>Q: </strong> How can I view my detailed repayment
                schedule?
                <br />
                <strong>A: </strong> You can view your detailed repayment
                schedule by accessing your loan account online or contacting
                your lender for the necessary information.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RepaymentSchedule;
