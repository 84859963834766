import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import themes from "../utils/themes";
import { useSelector } from "react-redux";
ChartJS.register(ArcElement, Tooltip, Legend);

const LoanTypeInsights = ({
  isEMIToPayHovered,
  setIsEMIToPayHovered,
  chartData,
}) => {
  const [hoveredItem, setHoveredItem] = useState(chartData[0] || null);

  const currentTheme = useSelector((state) => state.theme);

  const labels = chartData.map(
    (details) => details.type.charAt(0).toUpperCase() + details.type.slice(1)
  );
  const dataValues = chartData.map((details) => details.count);

  const data = {
    labels: labels,
    datasets: [
      {
        data: dataValues,
        backgroundColor: [
          `${themes[currentTheme].charts.accent1}`,
          `${themes[currentTheme].charts.accent2}`,
          `${themes[currentTheme].charts.accent3}`,
          `${themes[currentTheme].charts.accent4}`,
          `${themes[currentTheme].charts.accent5}`,
          `${themes[currentTheme].charts.accent6}`,
          `${themes[currentTheme].charts.accent7}`,
        ],
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {},
      },
      legend: {
        display: false,
        labels: {
          display: false,
        },
      },
    },
    onHover: (event, chartElements) => {
      if (chartElements && chartElements.length > 0) {
        setHoveredItem(chartData[chartElements[0]?.index] || null);
      }
    },
  };
  useEffect(() => {
    setHoveredItem(chartData[0] || null);
  }, [chartData]);

  const chartDataCSS = `${themes[currentTheme].colors.accentText} text-2xl font-semibold`;
  const chartDataCSS1 = `${themes[currentTheme].colors.text} font-semibold`;

  return (
    <div className="h-72 flex flex-col items-center">
      <div className={`flex items-center justify-center h-4/6 w-full gap-3 `}>
        <div className="h-full flex items-center  w-3/6">
          <Pie data={data} options={options} />
        </div>
        {hoveredItem && (
          <div className="w-3/6 flex flex-col justify-center items-center">
            <p className={`flex items-center justify-center ${chartDataCSS}`}>
              ₹ {hoveredItem.totalLoanAmount}
            </p>
            <p className="mt-4">
              <span
                className={`${themes[currentTheme].colors.accentText} font-semibold`}
              >
                {hoveredItem.remainingEmis}
              </span>{" "}
              EMIs Left
            </p>
          </div>
        )}
      </div>
      <div className="flex items-center justify-center w-full h-2/6">
        {hoveredItem && (
          <div className="grid grid-cols-2 gap-2">
            {/* <p className={`${chartDataCSS1} `}>
              {hoveredItem.type.charAt(0).toUpperCase() +
                hoveredItem.type.slice(1)}{" "}
              Loans
            </p> */}

            <p>
              Interest Paid:{" "}
              <span className={chartDataCSS1}>
                {hoveredItem.totalInterestPaid}
              </span>
            </p>
            <p>
              Outstanding:{" "}
              <span className={chartDataCSS1}>
                {hoveredItem.totalOutstandingAmount}
              </span>
            </p>

            <p className="col-span-2 flex justify-center gap-3 mt-2">
              {/* Lenders:{" "} */}
              {hoveredItem.lenders.map((lender, index) => (
                <div className={`${chartDataCSS1} `} key={index}>
                  <span
                    className={`${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonText} p-2 rounded-md`}
                  >
                    {lender}
                  </span>
                  {/* {`${index < hoveredItem.lenders.length - 1 ? ", " : ""}`} */}
                </div>
              ))}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoanTypeInsights;
