import React, { useState, useEffect } from "react";
import themes from "../utils/themes";
import { useSelector } from "react-redux";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import { Link } from "react-router-dom";
import { PropagateLoader } from "react-spinners";

const ClosedLoansInsights = () => {
  // Redux state for current theme
  const currentTheme = useSelector((state) => state.theme);
  const [closedLoanSummary, setClosedLoanSummary] = useState([]);
  const { token, username, logout, userId } = useAuth();
  const [loading, setLoading] = useState(true);

  const fetchClosedLoanSummary = async () => {
    try {
      const response = await axios.get(
        `http://localhost:5000/loans/closed-loan-summary`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setClosedLoanSummary(response.data);
      setLoading(false);
      console.log("Summary: ", response.data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClosedLoanSummary();
  }, [token]);

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Closed Loans Insights</h2>
      {loading ? (
        <div
          className={`flex justify-center items-center flex-col h-96 ${themes[currentTheme].colors.accentText}`}
        >
          <div className="h-8">
            <PropagateLoader
              color={`${themes[currentTheme].colors.loader}`}
              size={15}
              margin={2}
            />
          </div>
          <p className="text-center">Getting your Loan summary ready!</p>
        </div>
      ) : (
        <div className={``}>
          <div className={`mb-4`}>
            <ul>
              <li>Total Loans Closed: {closedLoanSummary.totalClosedLoans}</li>
              <li>
                Total Loan Amount:{" "}
                {closedLoanSummary.totalLoanAmountClosedLoans}
              </li>
              <li>
                Total Interest Paid:{" "}
                {closedLoanSummary.totalInterestPaidClosedLoans}
              </li>
              <li>
                Loan Types:
                <ul>
                  {Object.entries(closedLoanSummary.loanTypesSummary).map(
                    ([loanType, details]) => (
                      <li key={loanType}>
                        {loanType}:
                        <ul>
                          <li>Count: {details.count}</li>
                          <li>Total Loan Amount: {details.totalLoanAmount}</li>
                        </ul>
                      </li>
                    )
                  )}
                </ul>
              </li>
            </ul>
          </div>
          <Link to="/add">
            <button
              className={`${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonText} font-semibold  py-2 px-4 rounded-md focus:outline-none`}
            >
              Add Loan
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default ClosedLoansInsights;
