import React, { useState, useEffect } from "react";
import axios from "axios";
import { loginAPI } from "../utils/ListOfAPIs";

import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { FaEnvelope, FaLock } from "react-icons/fa";
import Logo from "../assets/Logo.png";
import { useSelector } from "react-redux";
import themes from "../utils/themes";
import LogoElement from "../assets/LogoElement";

const Login = () => {
  const { login } = useAuth();
  const initialFormData = {
    email: "",
    password: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [loginMessage, setLoginMessage] = useState("");
  const [logoColor1, setLogoColor1] = useState("#2dd4bf");
  const [logoColor2, setLogoColor2] = useState("#0f766e");

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const currentTheme = useSelector((state) => state.theme);

  
  useEffect(() => {
    setLogoColor1(themes[currentTheme].charts.accent4);
    setLogoColor2(themes[currentTheme].charts.accent6);
  }, [currentTheme]);

  const handleLogin = async () => {
    try {
      setIsLoggingIn(true);
      const response = await axios.post(loginAPI, formData);

      if (response.status === 200) {
        const { token, email, username, userId } = response.data; 
        login(token, email, username, userId); 
        setIsLoggingIn(false);
        setLoginMessage("Login successful!");
        console.log("User ID: ", userId);

        
        setTimeout(() => {
          setFormData(initialFormData);
        }, 1000);
        
        navigate("/");
        window.location.reload();
      } else {
        setLoginMessage("Invalid credentials. Please try again.");
      }
    } catch (error) {
      console.error("Error logging in:", error.message);
      setIsLoggingIn(false);
      setLoginMessage(`${error.response.data.error}`);
    }
  };
  
  const inputBoxStyle = `${themes[currentTheme].colors.input} ${themes[currentTheme].colors.inputText} p-2 w-full focus:outline-none rounded-lg`;

  return (
    <div
      className={`flex flex-col items-center justify-center h-screen w-screen ${themes[currentTheme].colors.card}`}
    >
      <div className="flex justify-center mb-6 items-center">
        <div className="w-24">
            <LogoElement logoColor1={logoColor1} logoColor2={logoColor2} />
          </div>
        <div
          className={`text-3xl font-semibold ${themes[currentTheme].colors.accentText}`}
        >
          LoanHub
        </div>
      </div>

      <div
        className={`${themes[currentTheme].colors.innerBackground} p-8 rounded-lg shadow-md w-[426px]`}
      >
        <h2 className="text-2xl font-bold mb-6 text-center">
          Hi there,
          <br />
          Log in to manage your loans
        </h2>

        <div className="mb-4">
          <div
            className={`flex items-center ${themes[currentTheme].colors.input} rounded-lg`}
          >
            <FaEnvelope className="w-12" />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={inputBoxStyle}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleLogin();
                }
              }}
            />
          </div>
        </div>
        <div className="mb-4">
          <div
            className={`flex items-center ${themes[currentTheme].colors.input} rounded-lg`}
          >
            <FaLock className="w-12" />
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className={inputBoxStyle}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleLogin();
                }
              }}
            />
          </div>
        </div>

        <button
          onClick={handleLogin}
          className={`w-full ${themes[currentTheme].colors.button} ${
            themes[currentTheme].colors.buttonText
          } py-2 rounded-lg ${
            themes[currentTheme].colors.buttonHover
          } transition duration-300 mb-2 ${
            isLoggingIn ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={isLoggingIn}
        >
          {isLoggingIn ? "Logging in..." : "Login"}
        </button>

        {loginMessage && (
          <div
            className={`${
              loginMessage.includes("successful")
                ? `${themes[currentTheme].colors.innerBackground} ${themes[currentTheme].colors.accentText}`
                : `${themes[currentTheme].colors.innerBackground} ${themes[currentTheme].colors.errorText}`
            } p-2 rounded-lg text-center`}
          >
            {loginMessage}
          </div>
        )}

        <div className="text-center py-2">
          New here? Join us!{" "}
          <Link
            to="/register"
            className={`${themes[currentTheme].colors.accentText} font-semibold hover:underline`}
          >
            Sign up now
          </Link>
        </div>

        <div className="text-center py-2">
          Need to verify your email?{" "}
          <Link
            to="/verifyemail"
            className={`${themes[currentTheme].colors.accentText} font-semibold hover:underline`}
          >
            Click Here
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
