import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineController,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";

import themes from "../utils/themes";
import { useSelector } from "react-redux";

const AmortizationChart = ({ chartData }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineController,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  const [hoveredItem, setHoveredItem] = useState(chartData[0] || null);
  const [selectedLoanIndex, setSelectedLoanIndex] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 640);

  const currentTheme = useSelector((state) => state.theme);

  const [periodicView, setPeriodicView] = useState("6m");

  const updateDateLabels = () => {
    const dueDates = chartData[selectedLoanIndex]?.timeline;
    const currentDate = new Date();

    const intervals = {
      "3m": 3,
      "6m": 6,
      "1y": 12,
      "3y": 36,
      "5y": 60,
      total: dueDates?.length,
    };

    const intervalLength = intervals[periodicView];

    const startIndex = dueDates?.findIndex((dueDate) => {
      const dueDateObj = new Date(dueDate);
      return (
        dueDateObj.getMonth() === currentDate.getMonth() &&
        dueDateObj.getFullYear() === currentDate.getFullYear()
      );
    });

    const adjustedStartIndex = Math.max(0, startIndex - intervalLength + 1);

    const dueDatesShown = dueDates?.slice(adjustedStartIndex, startIndex + 1);

    const dateLabels = dueDatesShown?.map((dateString) => {
      const date = new Date(dateString);
      return formatDate(date);
    });

    return dateLabels;
  };

  const handlePeriodicViewChange = (selectedView) => {
    setPeriodicView(selectedView);
  };
  const currentDate = new Date();
  const nextYear = currentDate.getFullYear() + 1;

  const dateLabels = Array.from({ length: 12 }, (_, index) => {
    const month = index + 1;

    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    return `${nextYear}-${formattedMonth}-01`;
  });

  const formatDate = (dateString) => {
    const options = {
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  };

  const dataCombined = {
    labels: updateDateLabels(periodicView),
    datasets: [
      {
        type: "line",
        label: `Outstanding Balance`,
        data: chartData[selectedLoanIndex]?.outstandingPrincipal,
        borderColor: `${themes[currentTheme].charts.accent5}`,
        fill: false,
        yAxisID: "y-axis-line",
      },
      {
        type: "bar",
        label: `Interest Paid`,
        data: chartData[selectedLoanIndex]?.totalInterestPaid,
        backgroundColor: `${themes[currentTheme].charts.accent4}`,
        yAxisID: "y-axis",
        stack: "stack1",
      },
      {
        type: "bar",
        label: `Principal Paid`,
        data: chartData[selectedLoanIndex]?.totalPrincipalPaid,
        backgroundColor: `${themes[currentTheme].charts.accent3}`,
        yAxisID: "y-axis",
        stack: "stack1",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {},
      },
      legend: {
        display: isSmallScreen ? false : true,
      },
    },
    scales: {
      x: { stacked: true, display: isSmallScreen ? false : true },
      "y-axis": {
        position: "left",
        beginAtZero: true,
      },
      "y-axis-line": {
        position: "right",
        beginAtZero: true,
      },
    },
  };

  useEffect(() => {
    setHoveredItem(chartData[selectedLoanIndex]?.timeline[0] || null);
  }, [chartData, selectedLoanIndex]);

  const chartDataCSS = `${themes[currentTheme].colors.accentText} text-2xl font-semibold`;
  const chartDataCSS1 = `${themes[currentTheme].colors.text}`;

  return (
    <div className="flex flex-col xl:flex-row xl:h-96 ml-4 pb-6">
      <div className="flex flex-col xl:flex-row items-center w-full xl:w-2/6">
        <div className="flex flex-col gap-2">
          {chartData[selectedLoanIndex] && (
            <>
              <p className={chartDataCSS}>
                ₹ {chartData[selectedLoanIndex].loan.loanAmount}
              </p>
              <p className={chartDataCSS1}>
                {chartData[selectedLoanIndex].loan.loanType
                  .charAt(0)
                  .toUpperCase() +
                  chartData[selectedLoanIndex].loan.loanType.slice(1)}{" "}
                Loan
              </p>
              {/* <p className={chartDataCSS1}>
                Total Loan Amount:{" "}
                {chartData[selectedLoanIndex].loan.loanAmount}
              </p> */}
              <p className={chartDataCSS1}>
                For {chartData[selectedLoanIndex].loan.loanTenure} Months
              </p>
              <p className={chartDataCSS1}>
                EMI: ₹ {chartData[selectedLoanIndex].loan.monthlyEMI}
              </p>
              <p className={chartDataCSS1}>
                Outstanding Interest: ₹{" "}
                {chartData[selectedLoanIndex].loan.outstandingInterest}
              </p>
              <p className={chartDataCSS1}>
                Outstanding Principal: ₹{" "}
                {chartData[selectedLoanIndex].loan.totalOutstanding -
                  chartData[selectedLoanIndex].loan.outstandingInterest}
              </p>
              <p className={chartDataCSS1}>
                Total Interest: ₹{" "}
                {chartData[selectedLoanIndex].loan.totalInterest}
              </p>
              <p className={chartDataCSS1}>
                Total Outstanding: ₹{" "}
                {chartData[selectedLoanIndex].loan.totalOutstanding}
              </p>
              <p className={chartDataCSS1}>EMIs:</p>

              <div className="flex justify-center items-center sm:gap-4 mt-2">
                <div className="flex flex-col justify-center items-center text-center w-24  border-r pr-5">
                  <p>{chartData[selectedLoanIndex].loan.paidEmis}</p>
                  <p>Paid</p>
                </div>
                <div className="flex flex-col justify-center items-center  text-center w-24 border-r pr-5">
                  <p>{chartData[selectedLoanIndex].loan.remainingEmis}</p>
                  <p>Remaining</p>
                </div>
                <div className="flex flex-col justify-center items-center text-center w-24  pr-5">
                  <p>{chartData[selectedLoanIndex].loan.overdueEmis}</p>
                  <p>Overdue</p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="flex flex-col justify-center items-center w-full xl:w-4/6 xl:mr-10">
        <div className="flex flex-col xl:flex-row gap-3 mb-2">
          <div>
            <label>Select Loan: </label>
            <select
              value={selectedLoanIndex}
              onChange={(e) =>
                setSelectedLoanIndex(parseInt(e.target.value, 10))
              }
              className={`rounded-md p-2 focus:outline-none ${themes[currentTheme].colors.innerBackground}`}
            >
              {chartData.map((loan, index) => (
                <option key={index} value={index}>
                  {loan.lenders} Loan - {formatDate(loan.loan.loanStartDate)}
                </option>
              ))}
            </select>
          </div>

          {/* Periodic View Dropdown */}
          <div>
            <label>Select Period: </label>
            <select
              value={periodicView}
              onChange={(e) => handlePeriodicViewChange(e.target.value)}
              className={`rounded-md p-2 focus:outline-none ${themes[currentTheme].colors.innerBackground}`}
            >
              <option value="3m">Last 3 Months</option>
              <option value="6m">Last 6 Months</option>
              <option value="1y">Last Year</option>
              <option value="3y">Last 3 Years</option>
              <option value="5y">Last 5 Years</option>
              <option value="total">Total</option>
            </select>
          </div>
        </div>

        <div className="w-full h-36 sm:h-64 xl:h-full">
          <Bar data={dataCombined} options={options} />
        </div>
      </div>
    </div>
  );
};

export default AmortizationChart;
