import { createContext, useContext, useState } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState({
    token: localStorage.getItem("token") || null,
    email: localStorage.getItem("email") || null,
    username: localStorage.getItem("username") || null,
    userId: localStorage.getItem("userId") || null,
    verified: localStorage.getItem("verified") || false,
  });

  const login = (token, email, username, userId, verified) => {
    setAuthData({
      token,
      email,
      username,
      userId,
      verified,
    });
    localStorage.setItem("token", token);
    localStorage.setItem("email", email);
    localStorage.setItem("username", username);
    localStorage.setItem("userId", userId);
    localStorage.setItem("verified", verified);
  };

  const logout = () => {
    setAuthData({
      token: null,
      email: null,
      username: null,
      userId: null,
      verified: authData.verified,
    });
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("username");
    localStorage.removeItem("userId");
  
  
   
  };

  return (
    <AuthContext.Provider value={{ ...authData, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
