import React, { useEffect, useState } from "react";
import axios from "axios";
import { resendCodeOnRequestAPI, verifyAPI, verifyCodeAPI,  } from "../utils/ListOfAPIs";
import { useParams, useNavigate } from "react-router-dom";
import Logo from "../assets/Logo.png";
import { useAuth } from "../contexts/AuthContext";
import themes from "../utils/themes";
import { useSelector } from "react-redux";
import { API_BASE_URL } from "../utils/apiConfig";

const VerifyAfterRegistration = () => {
  const [verified, setVerified] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [resendDisabled, setResendDisabled] = useState(true);
  const [resendTimer, setResendTimer] = useState(50);
  const [intervalId, setIntervalId] = useState(null);
  const [showCodeSentMessage, setShowCodeSentMessage] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  
  const currentTheme = useSelector((state) => state.theme);
  const { email } = useAuth();

  const { userId } = useParams();
  const navigate = useNavigate();
  const handleChange = (e) => {
    const code = e.target.value.replace(/[^0-9]/g, "");
    if (code.length <= 6) {
      setVerificationCode(code);
    }
    
    else {
      setVerificationCode(code.slice(0, 6));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    
    const code = verificationCode;

    try {
      const response = await axios.post(verifyCodeAPI, { code });

      if (
        response.data.message === "Email verified successfully! Please login"
      ) {
        setVerified(true);
        setTimeout(() => {
          navigate(`/login`);
        }, 3000);
      } else {
        setErrorMessage("Invalid Verification Code");
      }
    } catch (error) {
      console.error(error.response.data.message);
      setErrorMessage("Invalid Verification Code");
    }
  };

  const handleResendCode = async () => {
    console.log("Start sending...");
    setIsLoading(true);
    setResendDisabled(true);
    setErrorMessage("");
    try {
      const response = await axios.post(
        `${resendCodeOnRequestAPI}/${userId}`
      );
      if (response.data.message === "New verification code sent successfully") {
        startResendTimer();
        setIsCodeSent(true);
        setShowCodeSentMessage(true);

        
        setTimeout(() => {
          setShowCodeSentMessage(false);
        }, 10000);

        console.log(response.data);
      } else {
        console.error(
          "Error resending verification code:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Error resending verification code:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const startResendTimer = () => {
    const id = setInterval(() => {
      setResendTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(id);
          setResendDisabled(false);
          return 50;
        } else {
          return prevTimer - 1;
        }
      });
    }, 1000);

    
    setIntervalId(id);
  };

  useEffect(() => {
    startResendTimer();
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div
      className={`min-h-screen flex flex-col gap-4 items-center justify-center px-4 py-16 ${themes[currentTheme].colors.pageBackground}`}
    >
      <div className="flex justify-center gap-2 items-center">
        <div>
          <img className="w-16" src={Logo} alt="logo" />
        </div>
        <div
          className={`text-3xl font-semibold ${themes[currentTheme].colors.accentText}`}
        >
          LoanHub
        </div>
      </div>
      {!verified && (
        <div className="mb-3 text-center">
          You are one step away from accessing your account
        </div>
      )}

      <div
        className={`${themes[currentTheme].colors.card} p-8 rounded shadow-md w-96`}
      >
        {verified ? (
          <div>
            <p
              className={`${themes[currentTheme].colors.accentText} font-semibold text-lg text-center`}
            >
              Email verified successfully!
            </p>
            <div
              className={`${themes[currentTheme].colors.accentText} mt-5 animate-pulse rounded-lg text-center transition duration-300`}
            >
              Redirecting to Login Page...
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="flex flex-col gap-3">
            <h2 className="text-2xl font-semibold text-center mb-4">
              Enter Verification Code
            </h2>

            <div className="flex justify-center ">
              <input
                type="text"
                onChange={handleChange}
                value={verificationCode}
                className={`${themes[currentTheme].colors.input} ${themes[currentTheme].colors.inputText} text-5xl text-center p-2 w-full rounded-md focus:outline-none tracking-[.35em]`}
              />
            </div>
            <button
              type="submit"
              className={`${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonText} py-3   rounded-md `}
            >
              Verify
            </button>

            {isCodeSent && showCodeSentMessage && (
              <>
                <p className="text-center">
                  New verification code sent successfully to
                </p>
                <p
                  className={`${themes[currentTheme].colors.accentText} text-center font-semibold`}
                >
                  {email}
                </p>
              </>
            )}

            <p className="mt-2 flex flex-col gap-2 text-center">
              <p className="">Not received your code?</p>
              {isLoading ? (
                <span className="animate-pulse h-8">Sending...</span>
              ) : (
                <>
                  {resendDisabled ? (
                    
                    <span className="">
                      Resend code in {resendTimer} seconds
                    </span>
                  ) : (
                    <>
                      <button
                        type="button"
                        onClick={handleResendCode}
                        className={`${themes[currentTheme].colors.accentText} hover:text-lg transition-all duration-200 h-8  underline cursor-pointer`}
                      >
                        Resend Code
                      </button>
                    </>
                  )}
                </>
              )}
            </p>

            {errorMessage && (
              <p
                className={`text-center ${themes[currentTheme].colors.errorText}`}
              >
                {errorMessage}
              </p>
            )}
          </form>
        )}
      </div>
    </div>
  );
};

export default VerifyAfterRegistration;
