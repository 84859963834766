// PaymentHistory.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import { Link } from "react-router-dom";
import themes from "../utils/themes";
import { PropagateLoader } from "react-spinners";
import { IoOpenOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { API_BASE_URL } from "../utils/apiConfig";

const LoanSpecificPaymentHistory = ({ loan }) => {
  const { token } = useAuth();
  const currentTheme = useSelector((state) => state.theme);

  const formatDate = (dateString) => {
    const options = {
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  };
  console.log("Loan detial", loan);
  return (
    <div className={`flex flex-col gap-2 py-3 rounded-md px-5`}>
      {loan.length === 0 ? (
        <p className={`${themes[currentTheme].colors.textLight}`}>
          No payment records found. It seems you haven't made any payments yet.
        </p>
      ) : (
        <>
          {loan.emiData
            .filter((emi) => emi.status === "paid" && emi.paymentDate)
            .map((paidEmi, index) => (
              <div
                className={`${themes[currentTheme].colors.sidenavBackground} p-2 rounded-md flex items-center flex-grow gap-3 justify-between`}
                key={index}
              >
                <p>EMI Paid: ₹ {parseFloat(paidEmi.amount).toFixed(0)} </p>

                {/* <p>Paid on {paidEmi.lender}</p> */}
                {/* <p>
                      Paid on{" "}
                      {paidEmi.loanType.charAt(0).toUpperCase() +
                        paidEmi.loanType.slice(1)}
                    </p> */}
                <div
                  className={`flex gap-1 ${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonText} p-1 px-2 rounded-md`}
                >
                  <p>
                    {paidEmi.status.charAt(0).toUpperCase() +
                      paidEmi.status.slice(1)}
                  </p>
                  <p>on {formatDate(paidEmi.paymentDate)}</p>
                </div>
              </div>
            ))}
        </>
      )}
    </div>
  );
};

export default LoanSpecificPaymentHistory;
