import React, { useCallback, useState } from "react";

import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";
import themes from "../../utils/themes";
import { useSelector } from "react-redux";
import { insightsAPI } from "../../utils/ListOfAPIs";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";
import { PropagateLoader } from "react-spinners";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend
);

const LoanTenureOverview = ({
  cardHeadingCss,
  cardContentCss,
  dashboardData,
  payNowEmi,
  emiLoadingStates,
  unpayNowEmi,
}) => {
  const currentTheme = useSelector((state) => state.theme);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  const loanTenureData = dashboardData.userLoans
    .map((loan) =>
      loan.loanStatus === "active"
        ? {
            lender: loan.lender,
            totalTenure: loan.loanTenure,
            remainingTenure: loan.remainingEmis,
          }
        : null
    )
    .filter(Boolean);

  const barChartData = {
    labels: loanTenureData.map((loan) => loan.lender),
    datasets: [
      {
        label: "Total Tenure",
        data: loanTenureData.map((loan) => loan.totalTenure),
        backgroundColor: themes[currentTheme].charts.accent3,
        // barThickness: 30
      },
      {
        label: "Remaining Tenure",
        data: loanTenureData.map((loan) => loan.remainingTenure),
        backgroundColor: themes[currentTheme].charts.accent5,
        // barThickness: 30
      },
    ],
  };

  const barChartOptions = {
    // maintainAspectRatio: false,
    // indexAxis: 'y',
    scales: {
      x: {
        // stacked: true,
        display: isSmallScreen ? false : true,
      },
      y: {
        // stacked: true,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
    },
  };

  return (
    <div className={`flex w-full h-full ${themes[currentTheme].colors.text}`}>
      {!dashboardData ? (
        <div className="flex justify-center items-center w-full">
          <PropagateLoader
            color={`${themes[currentTheme].colors.loader}`}
            size={15}
            margin={2}
          />
        </div>
      ) : (
        <div className="flex flex-col w-full h-full">
          <h3 className={cardHeadingCss}>Loan Tenure Overview</h3>
          <div className="w-full h-36 sm:h-80 overflow-hidden">
            <Bar data={barChartData} options={barChartOptions} />
          </div>
        </div>
      )}
    </div>
  );
};

export default LoanTenureOverview;
