import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import themes from "../utils/themes";
import { API_BASE_URL } from "../utils/apiConfig";

const ProratedInterest = () => {
  const initialFormData = {
    interestRate: "",
    loanAmount: "",
    loanStartDate: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [interest, setInterest] = useState("");
  const [proratedInterest, setProratedInterest] = useState("");
  const [showFAQ, setShowFAQ] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInterestChange = (e) => {
    const { name, value } = e.target;
    if (/^(?:\d{1,2}|100)$/.test(value) || value === "") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const currentTheme = useSelector((state) => state.theme);

  const calculateProratedInterest = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${API_BASE_URL}/calculate/prorated-interest`,
        formData
      );
      setProratedInterest(response.data);
      setError("");
    } catch (error) {
      console.error("API error:", error);
      setError(error.response.data.error);
    } finally {
      setLoading(false);
    }
  };

  const toggleFAQ = () => {
    setShowFAQ(!showFAQ);
  };
  const inputBoxStyle = `${themes[currentTheme].colors.input} ${themes[currentTheme].colors.inputText} mt-1 p-2 w-full rounded-md focus:outline-none focus:border-blue-500`;
  return (
    <div
      className={`h-screen p-5 ${themes[currentTheme].colors.pageBackground}`}
    >
      <div
        className={`container mx-auto my-8 p-6 ${themes[currentTheme].colors.card} rounded-md shadow-md max-w-2xl`}
      >
        <h2 className="text-2xl font-bold mb-4">Calculate Prorated Interest</h2>

        <div className="mb-4">
          <label className="block text-sm font-medium ">Interest Rate</label>
          <input
            type="number"
            name="interestRate"
            value={formData.interestRate}
            onChange={handleInterestChange}
            required
            className={inputBoxStyle}
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium ">Loan Amount</label>
          <input
            type="number"
            name="loanAmount"
            value={formData.loanAmount}
            onChange={handleChange}
            required
            className={inputBoxStyle}
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium ">Loan Start Date</label>
          <input
            type="date"
            name="loanStartDate"
            value={formData.loanStartDate}
            onChange={handleChange}
            required
            className={inputBoxStyle}
          />
        </div>

        <button
          onClick={calculateProratedInterest}
          className={`${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonText} font-semibold py-2 px-4 rounded-md focus:outline-none`}
        >
          {loading ? "Calculating" : "Calculate"}
        </button>

        {error && (
          <p
            className={`${themes[currentTheme].colors.errorText} font-semibold mb-4`}
          >
            {error}
          </p>
        )}

        {proratedInterest && (
          <div className="mt-4">
            <strong>Prorated Interest:</strong> {proratedInterest}
          </div>
        )}

        <div className="mt-8 border-t pt-4">
          <button
            onClick={toggleFAQ}
            className={`${themes[currentTheme].colors.accentText} hover:underline focus:outline-none`}
          >
            {showFAQ ? "Hide FAQ" : "Show FAQ"}
          </button>

          {showFAQ && (
            <div className="mt-4">
              <h3 className="text-xl font-bold mb-2">FAQs</h3>
              <p>
                <strong>Q: </strong> What is prorated interest, and how is it
                calculated?
                <br />
                <strong>A: </strong> Prorated interest is a portion of the total
                interest calculated for a specific time period. It is often used
                in situations where a loan or financial arrangement begins or
                ends partway through a regular interest period.
              </p>
              <p>
                <strong>Q: </strong> How does the loan start date impact
                prorated interest?
                <br />
                <strong>A: </strong> The loan start date is crucial for prorated
                interest calculations as it determines the period for which
                interest is calculated. Interest is typically prorated based on
                the number of days the loan is active during a regular interest
                period.
              </p>
              <p>
                <strong>Q: </strong> Are there specific scenarios where prorated
                interest is commonly used?
                <br />
                <strong>A: </strong> Prorated interest is often applied in real
                estate transactions, lease agreements, and other situations
                where financial terms do not align with standard interest
                periods, such as monthly or annually.
              </p>
              <p>
                <strong>Q: </strong> Can prorated interest be negative?
                <br />
                <strong>A: </strong> Yes, prorated interest can be negative in
                certain scenarios. For example, if a borrower makes an early
                payment that exceeds the accrued interest, the prorated interest
                for that period may be negative.
              </p>
              <p>
                <strong>Q: </strong> Are there any legal considerations
                regarding prorated interest?
                <br />
                <strong>A: </strong> Legal considerations for prorated interest
                may vary by jurisdiction and the specific terms outlined in loan
                agreements or contracts. It's advisable to consult legal
                professionals to ensure compliance with applicable laws.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProratedInterest;
