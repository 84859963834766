import * as React from "react";
const LogoElement = (props, logoColor1, logoColor2) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 816 816.02" {...props}>
    <path
      d="M288.87 228.7c9.31-.2 18.94 1.37 28.29 1.2v309.98l111.16.4v47.81l-162.16-1.2V230.7c.5-1.13 19.94-1.93 22.71-1.99Zm243.44 147.43c.02 14.81-.78 29.77-.8 44.62-.05 41.81 1.69 83.68.8 125.51-1.12 2.52-48.73 1.37-54.98.8-.07-42.1.09-84.2 0-126.3-.03-14.84.68-29.83-.8-44.62l1.2-146.63 54.59 1.2c.04 48.48-.05 96.95 0 145.43Z"
      style={{
        fill:  props.logoColor1 ,
        strokeWidth: 0,
      }}
    />
    <path
      d="M476.53 376.13c1.48 14.8.76 29.78.8 44.62l-112.36-1.2.4-43.43h111.16Zm54.99 44.62c.02-14.86.81-29.82.8-44.62l15.14.4v43.03l-15.94 1.2Z"
      style={{
        fill:  props.logoColor2 ,
        strokeWidth: 0,
      }}
    />
  </svg>
);
export default LogoElement;
