import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import axios from "axios";
import { useAuth } from "../contexts/AuthContext";

import { addLoanAPI } from "../utils/ListOfAPIs";
import { useSelector } from "react-redux";
import themes from "../utils/themes";

const AddLoan = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useAuth();
  const currentTheme = useSelector((state) => state.theme);
  const [loading, setLoading] = useState(false);
  const initialFormData = {
    loanAmount: "",

    lender: "",
    emiDate: "",
    charges: "",
    loanStartDate: "",
    loanTenure: "",
    interestRate: "",
    loanType: "",
    note: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEMIDateChange = (e) => {
    const { name, value } = e.target;
    if (/^[1-9]$|^[12]\d$|^3[0-1]$/.test(value) || value === "") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await axios.post(addLoanAPI, formData, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Loan added successfully:", response.data);

      if (response.data.emiData) {
        console.log("EMIs from the backend:", response.data.emis);
      }
      setFeedbackMessage("Loan added successfully");
      setFormData(initialFormData);
      navigate(`/loans/${response.data._id}`, {
        replace: true,
        state: {
          from: location.pathname,
        },
      });
    } catch (error) {
      if (error.response) {
        console.error("Server Error:", error.response.data);
        setFeedbackMessage(`Server Error: ${error.response.data.error}`);
      } else if (error.request) {
        console.error("Network Error:", error.request);
        setFeedbackMessage("Network Error. Please try again.");
      } else {
        console.error("Error:", error.message);
        setFeedbackMessage("Error. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };
  console.log("Form Data", formData);
  const inputBoxStyle = `${themes[currentTheme].colors.input} ${themes[currentTheme].colors.inputText} mt-1 p-2 w-full rounded-md focus:outline-none`;

  return (
    <div
      className={`p-5 h-screen overflow-y-auto ${themes[currentTheme].colors.pageBackground}`}
    >
      <h2 className="text-2xl font-bold mb-4">Add Loan</h2>
      <div
        className={`p-6 rounded-md ${themes[currentTheme].colors.card} shadow-md`}
      >
        {feedbackMessage && feedbackMessage === "Loan added successfully" ? (
          <p
            className={`${themes[currentTheme].colors.accentText} font-semibold mb-4`}
          >
            {feedbackMessage}
          </p>
        ) : (
          <p
            className={`${themes[currentTheme].colors.errorText} font-semibold mb-4`}
          >
            {feedbackMessage}
          </p>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {/* Loan Amount */}
          <div>
            <label className="block text-sm font-medium ">Loan Amount</label>
            <input
              type="number"
              name="loanAmount"
              value={formData.loanAmount}
              onChange={handleChange}
              required
              className={inputBoxStyle}
            />
          </div>

          {/* Loan Tenure */}
          <div>
            <label className="block text-sm font-medium ">
              Loan Tenure (in months)
            </label>
            <input
              type="number"
              name="loanTenure"
              value={formData.loanTenure}
              onChange={handleChange}
              required
              className={inputBoxStyle}
            />
          </div>

          {/* Interest Rate */}
          <div>
            <label className="block text-sm font-medium ">
              Interest Rate (in %)
            </label>
            <input
              type="number"
              name="interestRate"
              value={formData.interestRate}
              onChange={handleChange}
              required
              className={inputBoxStyle}
            />
          </div>

          {/* Monthly EMI */}
          {/* <div>
          <label className="block text-sm font-medium ">
            Monthly EMI
          </label>

          <input
            type="number"
            name="monthlyEMI"
            value={formData.monthlyEMI}
            onChange={handleChange}
            required
            className="mt-1 p-2 w-3/4 border rounded-md focus:outline-none focus:border-blue-500"
          />
          
        </div> */}
          {/* Calculate EMI Button */}

          {/* <div>
          <label>
            Interest Calculation Method:
            <select
              name="interestMethod"
              value={formData.interestMethod}
              onChange={handleChange}
            >
              <option value="reducing">Reducing Balance</option>
              <option value="fixed">Fixed Interest</option>
              <option value="simple">Simple Interest</option>
            </select>
          </label>
        </div> */}

          {/* <div
          onClick={handleCalculateEMI}
          className="flex items-center text-white font-semibold bg-violet-400 mt-1 px-1 rounded-md cursor-pointer"
        >
          <div>Calculate EMI</div>
        </div> */}

          {/* Charges */}
          <div>
            <label className="block text-sm font-medium ">
              Charges (if any)
            </label>
            <input
              type="number"
              name="charges"
              value={formData.charges}
              onChange={handleChange}
              required
              className={inputBoxStyle}
            />
          </div>

          {/* Lender/Bank */}
          <div>
            <label className="block text-sm font-medium ">Lender/Bank</label>
            <input
              type="text"
              name="lender"
              value={formData.lender}
              onChange={handleChange}
              required
              className={inputBoxStyle}
            />
          </div>

          {/* EMI Due Date */}
          <div>
            <label className="block text-sm font-medium ">EMI Due Date</label>
            <input
              type="text"
              name="emiDate"
              value={formData.emiDate}
              onChange={handleEMIDateChange}
              required
              className={inputBoxStyle}
              pattern="^[1-9]$|^[12]\d$|^3[0-1]$"
            >
              {/* {Array.from({ length: 31 }, (_, i) => (
                <option key={i + 1} value={(i + 1).toString()}>
                  {i + 1}
                </option>
              ))} */}
            </input>
          </div>

          {/* Loan Start Date */}
          <div>
            <label className="block text-sm font-medium ">
              Loan Start Date
            </label>
            <input
              type="date"
              name="loanStartDate"
              value={formData.loanStartDate}
              onChange={handleChange}
              required
              className={inputBoxStyle}
            />
          </div>

          {/* Loan Type */}
          <div>
            <label className="block text-sm font-medium ">Loan Type</label>
            <select
              type="text"
              name="loanType"
              value={formData.loanType}
              onChange={handleChange}
              required
              className={inputBoxStyle}
            >
              <option>Select One</option>
              <option value="personal">Personal Loan</option>
              <option value="consumer">Consumer Loan</option>
              <option value="gold">Gold Loan</option>
              <option value="car">Car Loan</option>
              <option value="home">Home Loan</option>
              <option value="business">Business Loan</option>
              <option value="credit card">Credit Card Loan</option>
              <option value="bike">Bike Loan</option>
              <option value="others">Others</option>
            </select>
          </div>

          {/* Note */}
          <div>
            <label className="block text-sm font-medium ">Add a Note</label>
            <input
              type="text"
              name="note"
              value={formData.note}
              onChange={handleChange}
              required
              className={inputBoxStyle}
            />
          </div>
        </div>

        {/* Add Loan Button */}
        <button
          onClick={handleSubmit}
          className={`mt-6 ${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonHover} font-semibold ${themes[currentTheme].colors.buttonText} py-2 px-4 rounded-md focus:outline-none`}
          disabled={loading}
        >
          {loading ? "Adding Loan..." : "Add Loan"}
        </button>
      </div>
    </div>
  );
};

export default AddLoan;
