import { createStore, combineReducers } from 'redux';
import userProfilePictureReducer from './reducers/userReducer';
import themeReducer from './reducers/themeReducer';

const rootReducer = combineReducers({
  userProfilePicture: userProfilePictureReducer,
  theme: themeReducer,
});

const store = createStore(rootReducer);

export default store;
