import React, { useState, useEffect } from "react";
import axios from "axios";
import { allLoansAPI, insightsAPI } from "../utils/ListOfAPIs";
// import { Tooltip, Button } from "@material-tailwind/react";

import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { PropagateLoader } from "react-spinners";
import themes from "../utils/themes";
import { useSelector } from "react-redux";
import LoanTypeInsights from "../charts/LoanTypeInsights";
import EMIReport from "../components/insights/EMIReport";
import AmortizationChart from "../charts/AmortizationChart";
import { API_BASE_URL } from "../utils/apiConfig";
import LoanOverview from "../components/insights/LoanOverview";
import LoanTenureOverview from "../components/insights/LoanTenureOverview";
import { CiBank } from "react-icons/ci";
import DTIRatio from "../components/insights/DTIRatio";
import ClosedLoansInsights from "../components/ClosedLoansInsights";
const Insights = () => {
  const { token, username, logout, userId } = useAuth();
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const currentTheme = useSelector((state) => state.theme);
  const [payingEmi, setPayingEmi] = useState(false);
  const [loans, setLoans] = useState([]);
  const [isEMIToPayHovered, setIsEMIToPayHovered] = useState(false);
  const [isUpcomingEMIsHovered, setIsUpcomingEMIsHovered] = useState(false);

  const [emiLoadingStates, setEmiLoadingStates] = useState({});

  const fetchLoans = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${allLoansAPI}/all-loans`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const sortedLoans = response.data.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      setLoans(sortedLoans);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching loans:", error.message);
    }
  };
  useEffect(() => {
    fetchLoans();
  }, [token]);
  console.log(loans, "loans");
  const [emiAction, setEmiAction] = useState(false);

  const payNowEmi = async (loanId, emiId) => {
    setEmiLoadingStates((prevStates) => ({ ...prevStates, [emiId]: true }));
    try {
      const response = await axios.patch(
        `${API_BASE_URL}/loans/${loanId}/pay-now-emi/${emiId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setEmiAction(!emiAction);

      fetchLoans();
      setEmiLoadingStates((prevStates) => ({ ...prevStates, [emiId]: false }));
      console.log("EMI marked as paid successfully");
    } catch (error) {
      console.error("Error marking EMI as paid:", error.message);

      setEmiLoadingStates((prevStates) => ({ ...prevStates, [emiId]: false }));
    }
  };

  const unpayNowEmi = async (loanId, emiId) => {
    setEmiLoadingStates((prevStates) => ({ ...prevStates, [emiId]: true }));
    try {
      const response = await axios.patch(
        `${API_BASE_URL}/loans/${loanId}/mark-now-emi-unpaid/${emiId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setEmiAction(!emiAction);

      fetchLoans();
      setEmiLoadingStates((prevStates) => ({ ...prevStates, [emiId]: false }));
      console.log("EMI marked as unpaid successfully");
    } catch (error) {
      console.error("Error marking EMI as paid:", error.message);
      setEmiLoadingStates((prevStates) => ({ ...prevStates, [emiId]: false }));
    }
  };

  const [currentTime, setCurrentTime] = useState(new Date());
  const [currentDate, setCurrentDate] = useState(new Date());
  const [greeting, setGreeting] = useState("");

  useEffect(() => {
    const newDateTime = new Date();
    setCurrentTime(newDateTime);

    const currentHour = newDateTime.getHours();

    const greeting =
      currentHour >= 3 && currentHour < 12
        ? "Good Morning"
        : currentHour >= 12 && currentHour < 18
        ? "Good Afternoon"
        : currentHour >= 18 && currentHour < 22
        ? "Good Evening"
        : "Hi";
    setGreeting(greeting);
  }, []);

  const handleTokenExpiration = () => {
    logout();
    navigate("/login");
    window.location.reload();
  };

  const formattedTime = currentTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  const formattedDate = currentDate.toLocaleString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
  });
  const formatDate = (dateString) => {
    const options = {
      month: "short",
      day: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${insightsAPI}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDashboardData(response.data);
      console.log("Dash: ", response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          handleTokenExpiration();
        } else {
          console.error("Error fetching dashboard data:", error.message);
        }
      } else {
        console.error(
          "Non-Axios error fetching dashboard data:",
          error.message
        );
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [token, emiAction]);

  const [topLenders, setTopLenders] = useState([]);

  const [sortOption, setSortOption] = useState("count");

  const toggleSortOption = () => {
    // Toggle between "count" and "sum" when called
    setSortOption((prevOption) => (prevOption === "count" ? "sum" : "count"));
  };

  // Modify the sorting logic based on the sortOption
  const getSortedLenders = (lenderArray) => {
    return lenderArray.slice().sort((a, b) => {
      if (sortOption === "count") {
        return b.count - a.count;
      } else {
        return b.sum - a.sum;
      }
    });
  };

  const getTopLenders = async () => {
    if (dashboardData && dashboardData.userLoans) {
      const lenderData = dashboardData.userLoans.reduce((data, loan) => {
        const lender = loan.lender;
        const loanAmount = loan.loanAmount;

        if (!data[lender]) {
          // If lender is not in data, initialize it with count and sum
          data[lender] = {
            count: 1,
            sum: loanAmount,
          };
        } else {
          // If lender is already in data, update count and sum
          data[lender].count += 1;
          data[lender].sum += loanAmount;
        }

        return data;
      }, {});

      // Convert lenderData object to an array of objects for sorting
      const lenderArray = Object.keys(lenderData).map((lender) => ({
        lender,
        count: lenderData[lender].count,
        sum: lenderData[lender].sum,
      }));

      // Sort the array based on count in descending order
      const sortedLenderArray = getSortedLenders(lenderArray);

      setTopLenders([...sortedLenderArray]);
    }
  };

  useEffect(() => {
    getTopLenders();
  }, [dashboardData, sortOption]);

  // console.log(topLenders); // This will log the topLenders after the useEffect has run
  // const [user, setUser] = useState(null)
  //   const getUser = async () => {
  //     try {
  //       const response = await axios.get(`${API_BASE_URL}/user/${userId}`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });

  //       setUser(response.data);
  //     } catch (error) {
  //       console.error(error.response.data.error);
  //     }
  //   };

  const cardCss = `${themes[currentTheme].colors.card} p-4 rounded-md shadow-md`;
  const cardHeadingCss = "text-lg font-semibold mb-2";
  const cardContentCss = `text-xl font-semibold ${themes[currentTheme].colors.accentText}`;

  return (
    <div
      className={`p-5 h-screen overflow-y-auto ${themes[currentTheme].colors.pageBackground}`}
    >
      {dashboardData?.numberOfActiveLoans !== 0 ? (
        <div>
          <div className="flex justify-between items-start">
            <div className="text-xl sm:text-2xl md:text-5xl mb-5 ">
              {greeting}{" "}
              <span
                className={`${themes[currentTheme].colors.accentText} font-semibold`}
              >
                {username}
              </span>
              ,
            </div>
            <div
              className={`${themes[currentTheme].colors.accentText} mt-3 font-semibold`}
            >
              {formattedTime}, {formattedDate}
            </div>
          </div>
          {loading ? (
            <div
              className={`flex justify-center items-center h-96 flex-col ${themes[currentTheme].colors.accentText}`}
            >
              <div className="h-8">
                <PropagateLoader
                  color={`${themes[currentTheme].colors.loader}`}
                  size={15}
                  margin={2}
                />
              </div>
              <p className="text-center">
                Brewing your custom blend of financial insights
                <br />
                Patience makes the perfect cup!
              </p>
            </div>
          ) : (
            <>
              {dashboardData && (
                <>
                  <div className="grid grid-cols-1 xl:grid-cols-6 gap-2  xl:gap-6">
                    <div className={`${cardCss} col-span-full xl:col-span-3`}>
                      <LoanOverview
                        cardHeadingCss={cardHeadingCss}
                        cardContentCss={cardContentCss}
                        dashboardData={dashboardData}
                        payNowEmi={payNowEmi}
                        emiLoadingStates={emiLoadingStates}
                        unpayNowEmi={unpayNowEmi}
                      />
                    </div>

                    <div className={`${cardCss} col-span-full xl:col-span-2`}>
                      <h3 className={cardHeadingCss}>
                        Lenders
                        <button
                          className={`ml-2 text-sm ${themes[currentTheme].colors.textLight}`}
                          onClick={toggleSortOption}
                        >
                          Sort by {sortOption === "count" ? "Sum" : "Count"}
                        </button>
                      </h3>
                      <div className="overflow-y-auto ">
                        {topLenders.map((lender, index) => (
                          <div
                            key={index}
                            className="flex items-center py-1 mb-2"
                          >
                            <div className="w-7/12 flex items-center">
                              {lender.lender}
                            </div>
                            <div
                              className={`w-2/12 ${
                                sortOption === "count" &&
                                `font-semibold ${themes[currentTheme].colors.accentText}`
                              }`}
                            >
                              {lender.count}
                            </div>
                            <div
                              className={`w-3/12 ${
                                sortOption === "sum" &&
                                `font-semibold ${themes[currentTheme].colors.accentText}`
                              }`}
                            >
                              ₹ {lender.sum}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className={`${cardCss} col-span-full xl:col-span-1`}>
                      <DTIRatio
                        cardHeadingCss={cardHeadingCss}
                        cardContentCss={cardContentCss}
                        dashboardData={dashboardData}
                        payNowEmi={payNowEmi}
                        emiLoadingStates={emiLoadingStates}
                        unpayNowEmi={unpayNowEmi}
                      />
                    </div>

                    <div className={`${cardCss} col-span-full xl:col-span-4 `}>
                      <EMIReport
                        cardHeadingCss={cardHeadingCss}
                        // cardContentCss={cardContentCss}
                        dashboardData={dashboardData}
                        payNowEmi={payNowEmi}
                        emiLoadingStates={emiLoadingStates}
                        unpayNowEmi={unpayNowEmi}
                      />
                    </div>

                    <div className={`${cardCss} col-span-full xl:col-span-2`}>
                      <h3 className={cardHeadingCss}>Loan Distribution</h3>
                      <LoanTypeInsights
                        isEMIToPayHovered={isEMIToPayHovered}
                        setIsEMIToPayHovered={setIsEMIToPayHovered}
                        chartData={dashboardData.loanTypeDetails}
                      />
                    </div>
                  </div>
                  <div className="mt-6 grid grid-cols-1 xl:grid-cols-7 gap-2 xl:gap-6">
                    <div className={`${cardCss} col-span-full xl:col-span-3`}>
                      <h3 className={cardHeadingCss}>
                        Repayment Status Overview
                      </h3>
                      {loading ? (
                        <div
                          className={`flex justify-center items-center flex-col h-96 ${themes[currentTheme].colors.accentText}`}
                        >
                          <PropagateLoader
                            color={`${themes[currentTheme].colors.loader}`}
                            size={15}
                            margin={2}
                          />
                        </div>
                      ) : (
                        <div className="h-72 overflow-auto pr-2">
                          {loans.map((loan) => (
                            <>
                              <div className="flex justify-between items-center">
                                <Link to={`/loans/${loan._id}`} key={loan._id}>
                                  {loan.loanType.charAt(0).toUpperCase() +
                                    loan.loanType.slice(1)}{" "}
                                  / {loan.lender}
                                </Link>
                                <h2 className=" font-bold mb-4">{`${
                                  loan.paidEmis
                                } / ${
                                  loan.paidEmis + loan.remainingEmis
                                } EMIs paid`}</h2>
                              </div>
                              <div
                                style={{
                                  height: "1rem",
                                  backgroundColor: `${themes[currentTheme].colors.spare}`,
                                  borderRadius: "0.75rem",
                                  overflow: "hidden",
                                }}
                              >
                                <div
                                  style={{
                                    height: "100%",
                                    width: `${(
                                      (loan.paidEmis /
                                        (loan.paidEmis + loan.remainingEmis)) *
                                      100
                                    ).toFixed(2)}%`,
                                    backgroundColor: `${themes[currentTheme].colors.loader}`,
                                    transition: "width 0.3s ease-in-out",
                                  }}
                                ></div>
                              </div>
                            </>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className={`${cardCss} col-span-full xl:col-span-4`}>
                      <LoanTenureOverview
                        cardHeadingCss={cardHeadingCss}
                        cardContentCss={cardContentCss}
                        dashboardData={dashboardData}
                        payNowEmi={payNowEmi}
                        emiLoadingStates={emiLoadingStates}
                        unpayNowEmi={unpayNowEmi}
                      />
                    </div>
                  </div>
                  <div className="mt-6 grid grid-cols-1 xl:grid-cols-4 gap-6">
                    <div className={`${cardCss} col-span-full xl:col-span-4`}>
                      <h3 className={cardHeadingCss}>Amortization Chart</h3>
                      <AmortizationChart
                        chartData={dashboardData.loanPerformanceTrends}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {/* Add Loan Button */}
          <div className="mt-6">
            {/* Add a link to the add loan page */}
            <Link to="/add">
              <button
                className={`font-semibold ${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonText}   py-2 px-4 rounded-md  focus:outline-none`}
              >
                Add Loan
              </button>
            </Link>
          </div>
        </div>
      ) : dashboardData?.numberOfActiveLoans === 0 &&
        dashboardData?.numberOfLoans > 0 ? (
        <ClosedLoansInsights dashboardData={dashboardData} />
      ) : (
        dashboardData?.numberOfActiveLoans === 0 &&
        dashboardData?.numberOfLoans === 0 && (
          <div className="h-screen flex flex-col justify-center items-center">
            <h1 className="text-4xl text-center mb-5">
              Seems you haven't added any loans. Add them to see insights!
            </h1>
            <Link to="/add">
              <button
                className={`font-semibold ${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonText}   py-2 px-4 rounded-md  focus:outline-none`}
              >
                Add Loan
              </button>
            </Link>
          </div>
        )
      )}
    </div>
  );
};

export default Insights;
