import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useAuth } from "./contexts/AuthContext";
import ProtectedRoute from "./utils/ProtectedRoute";

import Login from "./pages/Login";
import Register from "./pages/Register";
import Sidebar from "./components/Sidebar";
import Insights from "./pages/Insights";
import LoanList from "./pages/LoanList";
import LoanDetails from "./pages/LoanDetails";
import EditLoan from "./pages/EditLoan";
import AddLoan from "./pages/AddLoan";
import UserProfile from "./pages/UserProfile";
import VerifyFromLogin from "./pages/VerifyFromLogin";
import Logout from "./pages/Logout";
import Test from "./pages/Test";
import MonthlyInterestRate from "./calculators/MonthlyInterestRate";
import NotFound from "./pages/NotFound";
import VerifyAfterRegistration from "./pages/VerifyAfterRegistration";
import ProratedInterest from "./calculators/ProratedInterest";
import RepaymentSchedule from "./calculators/RepaymentSchedule";
import { useDispatch, useSelector } from "react-redux";
import { firebaseConfig } from "./utils/firebase";
import {
  fetchUserProfilePicture,
  setUserProfilePicture,
} from "./actions/userActions";
import themes from "./utils/themes";
import PaymentHistory from "./pages/PaymentHistory";
import { API_BASE_URL } from "./utils/apiConfig";

const App = () => {
  const { token, userId, verified } = useAuth();
  const dispatch = useDispatch();
  const [showSidebar, setShowSidebar] = useState(!token && verified === false);
  const [user, setUser] = useState();
  const currentTheme = useSelector((state) => state.theme);
  // const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1366);
  console.log("Token: ", token);
  useEffect(() => {
    setShowSidebar(!!token && verified);
  }, [token, verified]);

  useEffect(() => {
    // dispatch(fetchUserProfilePicture());
    const fetchUserData = async () => {
      if (!token) {
        // Token is null, skip the API request.
        return;
      }
      try {
        const response = await axios.get(`${API_BASE_URL}/user/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // const userProfilePicture = response.data.profilePicture;
        // dispatch(setUserProfilePicture(userProfilePicture));
        console.log(response.data);
        setUser(response.data);
        console.log("Logged out before catch");
      } catch (error) {
        console.log("Logged out after catch");
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [userId, dispatch]);

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);

  useEffect(() => {
    dispatch(fetchUserProfilePicture());

    const getProfilePicture = async () => {
      try {
        // Assuming you have a unique identifier for the user's profile picture
        // const pictureRef = ref(storage, `images/${userId}/profilePicture`);
        const pictureRef = ref(storage, `images/${userId}/profilePicture`);
        const pictureURL = await getDownloadURL(pictureRef);

        dispatch(setUserProfilePicture(pictureURL));
      } catch (error) {
        console.error("Error fetching profile picture:", error);
      }
    };
    getProfilePicture();
  }, [userId, storage]);

  return (
    <BrowserRouter>
      {/* {isSmallScreen ? (
        <div className="flex flex-col justify-center items-center text-2xl gap-4 h-screen">
          <div className="flex justify-center items-center text-center ">
            This application is not optimized for mobile or tablet usage.
          </div>
          <div>Proceed anyway?</div>
          <button
            onClick={()=>{setIsSmallScreen(false)}}
            className={`p-2 px-6 rounded-lg ${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonText} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHoverText}`}
          >
            Yes
          </button>
        </div>
      ) : ( */}
        <div
          className={`flex h-screen ${themes[currentTheme].colors.text} font-Nunito text-sm`}
        >
          <div>{showSidebar && <Sidebar />}</div>
          {/* <div>{token}</div> */}
          <div className="w-full">
            <Routes>
              {/* Public routes */}

              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/verifyemail" element={<VerifyFromLogin />} />
              <Route
                path="/verify/:userId"
                element={<VerifyAfterRegistration />}
              />
              <Route path="test" element={<Test />} />
              <Route path="logout" element={<Logout />} />

              {/* Protected routes */}
              <Route path="/" element={<ProtectedRoute />}>
                <Route index element={<Insights />} />
                <Route path="profile" element={<UserProfile />} />
                <Route path="loans" element={<LoanList />} />
                <Route path="loans/:id" element={<LoanDetails />} />
                <Route path="/edit-loan/:id" element={<EditLoan />} />
                <Route path="add" element={<AddLoan />} />
                <Route path="payment-history" element={<PaymentHistory />} />
              </Route>

              {/* Calculators */}
              <Route
                path="calculate/monthly-interest-rate"
                element={<MonthlyInterestRate />}
              />
              <Route
                path="calculate/prorated-interest"
                element={<ProratedInterest />}
              />
              <Route
                path="calculate/repayment-schedule"
                element={<RepaymentSchedule />}
              />

              {/* Not Found */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      {/* )} */}
    </BrowserRouter>
  );
};

export default App;
