const initialState = {
    userProfilePicture: null,
  };
  
  export default function userProfilePictureReducer(state = initialState, action) {
    switch (action.type) {
      case 'FETCH_USER_PROFILE_PICTURE':
        return { ...state, isLoading: true };
      case 'SET_USER_PROFILE_PICTURE':
        return { ...state, isLoading: false, userProfilePicture: action.payload };
      default:
        return state;
    }
  }
  