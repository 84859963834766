import React from "react";
import { Link } from "react-router-dom";
import NotFoundImage from "../assets/NotFound.png";
import {useSelector} from 'react-redux'
import themes from "../utils/themes"
const NotFound = () => {
  const currentTheme = useSelector((state)=> state.theme)
  return (
    <div className={`min-h-screen flex flex-col items-center justify-center ${themes[currentTheme].colors.card}`}>
      {/* Hero Section */}
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-4">Oh no! We couldn't find that page.</h1>
        <p className="text-lg mb-8">
          Don't worry, we'll get you back on track.
        </p>
        <img
          src={NotFoundImage}
          alt="404 Illustration"
          className="h-64 mx-auto"
        />
      </div>

      {/* Navigation Section */}
      <div className="flex mt-8 space-x-4">
        <Link
          to="/"
          className={`${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonText} px-4 py-2 rounded-md`}
        >
          <span className="flex items-center">Go back to home</span>
        </Link>

        <div className={`${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonText} px-4 py-2 rounded-md`}>
          <a href={`mailto:hello@sashankgl.xyz`}>Need help? Contact us</a>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
