import React, { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import axios from "axios";
import { API_BASE_URL } from "./apiConfig";
import { PropagateLoader } from "react-spinners";
import themes from "./themes";
import { useSelector } from "react-redux";

const ProtectedRoute = () => {
  const { token, userId, verified, setVerified } = useAuth();
  const [loading, setLoading] = useState(true);
  const [shouldRender, setShouldRender] = useState(false);
  const currentTheme = useSelector((state) => state.theme);

  useEffect(() => {
    const checkUserVerification = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/user/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const isUserVerfied = response.data.verified;

        if (!isUserVerfied) {
          setVerified(false);
        }
        setLoading(false);
        setShouldRender(true);
      } catch (error) {
        console.error("Error verifying User: ", error);
        setLoading(false);
        setShouldRender(true);
      }
    };

    if (token && userId) {
      checkUserVerification();
    } else {
      setLoading(false);
      setShouldRender(true);
    }
  }, [token, userId, setVerified]);

  if (loading) {
    return (
      <div
        className={`flex justify-center items-center flex-col h-screen ${themes[currentTheme].colors.accentText}`}
      >
        <div className="flex flex-col items-center gap-8">
          <div className="h-full">
            <PropagateLoader
              color={`${themes[currentTheme].colors.loader}`}
              size={15}
              margin={2}
            />
          </div>
          <p className="text-center">Authorizing</p>
        </div>
      </div>
    );
  }

  return shouldRender && token && verified ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoute;
