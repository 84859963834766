import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import { useAuth } from "../contexts/AuthContext";
import { allLoansAPI } from "../utils/ListOfAPIs";
import { useParams, useNavigate } from "react-router-dom";
import { PropagateLoader } from "react-spinners";
import { useSelector } from "react-redux";
import themes from "../utils/themes";
import {
  AiFillBank,
  AiOutlineHome,
  AiOutlineCar,
  AiOutlineCreditCard,
  AiOutlineUser,
  AiOutlineGold,
} from "react-icons/ai";
import { MdOutlineGridView } from "react-icons/md";
import { MdCalendarViewDay } from "react-icons/md";
import { MdTableChart } from "react-icons/md";

import { LuMoreVertical } from "react-icons/lu";
import { API_BASE_URL } from "../utils/apiConfig";

const LoanList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [viewMode, setViewMode] = useState("tile");

  const [loans, setLoans] = useState([]);
  const [originalLoans, setOriginalLoans] = useState([]);
  const [isDelete, setIsDelete] = useState(false);
  const { token } = useAuth();
  const currentTheme = useSelector((state) => state.theme);

  const [loanStatusFilter, setLoanStatusFilter] = useState(null);
  const [loanTypeFilter, setLoanTypeFilter] = useState(null);

  const [precloseStates, setPrecloseStates] = useState({});
  const [showOptions, setShowOptions] = useState({});

  const [sortCriteria, setSortCriteria] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc"); // 'asc' or 'desc'

  const togglePrecloseState = (loanId) => {
    setPrecloseStates((prevPrecloseStates) => ({
      ...prevPrecloseStates,
      [loanId]: !prevPrecloseStates[loanId],
    }));
  };

  const toggleOptions = (loanId) => {
    setShowOptions((prevShowOptions) => ({
      ...prevShowOptions,
      [loanId]: !prevShowOptions[loanId],
    }));
  };

  const [deleteStates, setDeleteStates] = useState({});

  const toggleDeleteState = (loanId) => {
    setDeleteStates((prevDeleteStates) => ({
      ...prevDeleteStates,
      [loanId]: !prevDeleteStates[loanId],
    }));
  };

  const renderLoanList = () => {
    switch (viewMode) {
      case "card":
        return loans.map((loan) => (
          <div key={loan._id} className="p-3 relative">
            <div
              key={loan._id}
              className={`p-3 relative ${themes[currentTheme].colors.card} rounded-md shadow-md`}
            >
              <div className="flex flex-col gap-2">
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-2 font-semibold">
                    {loanTypeIcons[loan.loanType] &&
                      loanTypeIcons[loan.loanType]({ fontSize: "22px" })}
                    <p className="text-2xl">{loan.lender}</p>
                  </div>
                  <div>
                    <LuMoreVertical
                      onClick={() => toggleOptions(loan._id)}
                      className="cursor-pointer p-2 text-4xl"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-5 gap-4 my-3">
                  <p>
                    {loan.loanType.charAt(0).toUpperCase() +
                      loan.loanType.slice(1)}{" "}
                    Loan
                  </p>
                  <p>Amount: ₹ {loan.loanAmount}</p>
                  <p>Interest Rate: {loan.interestRate.$numberDecimal} %</p>
                  <p>Monthly EMI: ₹ {loan.monthlyEMI}</p>

                  <p>
                    Loan Status:
                    <span className={`p-1 ${getStatusColor(loan.loanStatus)}`}>
                      {loan.loanStatus.charAt(0).toUpperCase() +
                        loan.loanStatus.slice(1)}
                    </span>
                  </p>
                </div>

                <div className="flex justify-between items-center">
                  <h2 className="text-xl font-semibold mb-2">
                    Loan Payment Progress
                  </h2>
                  <h2 className="text-xl font-semibold mb-2">{`${
                    loan.paidEmis
                  } / ${loan.paidEmis + loan.remainingEmis} EMIs paid`}</h2>
                </div>
                <div
                  style={{
                    height: "1rem",
                    backgroundColor: `${themes[currentTheme].colors.spare}`,
                    borderRadius: "0.5rem",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      height: "100%",
                      width: `${(
                        (loan.paidEmis / (loan.paidEmis + loan.remainingEmis)) *
                        100
                      ).toFixed(2)}%`,
                      backgroundColor: `${themes[currentTheme].colors.loader}`,
                      transition: "width 0.3s ease-in-out",
                    }}
                  ></div>
                </div>
              </div>

              {showOptions[loan._id] && (
                <div className="absolute right-12 top-3 flex flex-col justify-center mt-2">
                  <Link
                    to={`/loans/${loan._id}`}
                    className={`px-2 py-1 w-36  text-center rounded-t-lg ${themes[currentTheme].colors.innerBackground} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHoverText} hover:font-semibold active:scale-95 cursor-pointer`}
                  >
                    View Details
                  </Link>
                  <Link
                    to={`/edit-loan/${loan._id}`}
                    className={`px-2 py-1 w-36  text-center ${themes[currentTheme].colors.innerBackground} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHoverText} hover:font-semibold active:scale-95 cursor-pointer`}
                  >
                    Edit Loan
                  </Link>
                  <div
                    onClick={() => toggleDeleteState(loan._id)}
                    className={`px-2 py-1 w-36  text-center ${themes[currentTheme].colors.innerBackground} ${themes[currentTheme].colors.errorHover} ${themes[currentTheme].colors.buttonHoverText} hover:font-semibold active:scale-95 cursor-pointer`}
                  >
                    Delete
                  </div>
                  {loan.loanStatus !== "closed" && (
                    <div
                      onClick={() => togglePrecloseState(loan._id)}
                      className={`px-2 py-1 w-36  text-center rounded-b-lg ${themes[currentTheme].colors.innerBackground} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHoverText} hover:font-semibold active:scale-95 cursor-pointer`}
                    >
                      Settle Loan Early
                    </div>
                  )}
                </div>
              )}

              {precloseStates[loan._id] && (
                <div
                  className={`absolute inset-0 h-full w-full ${themes[currentTheme].colors.card} bg-opacity-50 flex justify-center items-center`}
                >
                  <div
                    className={`${themes[currentTheme].colors.innerBackground}  p-4 px-8  rounded-lg`}
                  >
                    <h2 className="text-2xl font-semibold">
                      Settle Loan Early
                    </h2>
                    <p className="text-gray-600">
                      Are you sure you want to settle the loan early?
                    </p>
                    <div className="flex gap-4 mt-6">
                      <div
                        onClick={() => handlePreclosure(loan._id)}
                        className={`px-4 py-2 w-24  text-center rounded-lg ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHoverText} hover:font-semibold active:scale-95 cursor-pointer`}
                      >
                        Yes
                      </div>
                      <div
                        onClick={() => togglePrecloseState(loan._id)}
                        className={`px-4 py-2 w-24  text-center rounded-lg ${themes[currentTheme].colors.errorHover} ${themes[currentTheme].colors.buttonHoverText} hover:font-semibold active:scale-95 cursor-pointer`}
                      >
                        No
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {deleteStates[loan._id] && (
                <div
                  className={`absolute inset-0 h-full w-full ${themes[currentTheme].colors.card} bg-opacity-50 flex justify-center items-center`}
                >
                  <div
                    className={`${themes[currentTheme].colors.innerBackground}  p-4 px-8  rounded-lg`}
                  >
                    <h2 className="text-2xl font-semibold">Delete Loan</h2>
                    <p className="text-gray-600">
                      Are you sure you want to delete this loan?
                    </p>
                    <div className="flex gap-4 mt-6">
                      <div
                        onClick={() => handleDelete(loan._id)}
                        className={`px-4 py-2 w-24  text-center rounded-lg ${themes[currentTheme].colors.errorHover} ${themes[currentTheme].colors.buttonHoverText} hover:font-semibold active:scale-95 cursor-pointer`}
                      >
                        Yes
                      </div>
                      <div
                        onClick={() => toggleDeleteState(loan._id)}
                        className={`px-4 py-2 w-24  text-center rounded-lg ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHoverText} hover:font-semibold active:scale-95 cursor-pointer`}
                      >
                        No
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ));
      case "table":
        return (
          <div className="flex-grow overflow-auto">
            <table className="w-full table-auto border-collapse border">
              <thead>
                <tr className={`${themes[currentTheme].colors.card}`}>
                  <th className="border px-4 py-2">Lender</th>
                  <th className="border px-4 py-2">Loan Type</th>
                  <th className="border px-4 py-2">Amount</th>
                  <th className="border px-4 py-2">Interest Rate (%)</th>
                  <th className="border px-4 py-2">Monthly EMI</th>
                  <th className="border px-4 py-2">Loan Status</th>
                  <th className="border px-4 py-2">EMIs Paid</th>
                  <th className="border px-4 py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {loans.map((loan) => (
                  <tr key={loan._id} className="text-center">
                    <td className="border px-4 py-2">{loan.lender}</td>
                    <td className="border px-4 py-2">
                      {loan.loanType.charAt(0).toUpperCase() +
                        loan.loanType.slice(1)}{" "}
                    </td>
                    <td className="border px-4 py-2">₹ {loan.loanAmount}</td>
                    <td className="border px-4 py-2">
                      {loan.interestRate.$numberDecimal}
                    </td>
                    <td className="border px-4 py-2">₹ {loan.monthlyEMI}</td>
                    <td className="border px-4 py-2">
                      <span
                        className={`py-1 px-2 ${getStatusColor(
                          loan.loanStatus
                        )}`}
                      >
                        {loan.loanStatus.charAt(0).toUpperCase() +
                          loan.loanStatus.slice(1)}
                      </span>
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {loan.paidEmis} / {loan.paidEmis + loan.remainingEmis}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      <Link
                        to={`/loans/${loan._id}`}
                        className={`px-4 py-1 rounded-lg mr-2 text-center ${themes[currentTheme].colors.card} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHoverText} hover:font-semibold active:scale-95 cursor-pointer`}
                      >
                        View
                      </Link>
                      <Link
                        to={`/edit-loan/${loan._id}`}
                        className={`px-4 py-1 rounded-lg mr-2 text-center ${themes[currentTheme].colors.card} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHoverText} hover:font-semibold active:scale-95 cursor-pointer`}
                      >
                        Edit
                      </Link>
                      <button
                        onClick={() => toggleDeleteState(loan._id)}
                        className={`px-4 py-1 rounded-lg mr-2 text-center ${themes[currentTheme].colors.card} ${themes[currentTheme].colors.errorHover} ${themes[currentTheme].colors.buttonHoverText} hover:font-semibold active:scale-95 cursor-pointer`}
                      >
                        Delete
                      </button>
                      {loan.loanStatus !== "closed" && (
                        <button
                          onClick={() => togglePrecloseState(loan._id)}
                          className={`px-4 py-1 rounded-lg mr-2 text-center ${themes[currentTheme].colors.card} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHoverText} hover:font-semibold active:scale-95 cursor-pointer`}
                        >
                          Settle Loan Early
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {loans.map((loan) => (
              <>
                {precloseStates[loan._id] && (
                  <div
                    className={`absolute inset-0 h-full w-full ${themes[currentTheme].colors.card} bg-opacity-50 flex justify-center items-center`}
                  >
                    <div
                      className={`${themes[currentTheme].colors.innerBackground}  p-4 px-8  rounded-md`}
                    >
                      <div className="mb-2">
                        Are you sure you want to preclose this loan?
                      </div>
                      <div className="flex justify-center gap-3">
                        <button
                          onClick={() => handlePreclosure(loan._id)}
                          className={`${themes[currentTheme].colors.error} ${themes[currentTheme].colors.errorHover} ${themes[currentTheme].colors.buttonText} py-2 px-6 rounded-md focus:outline-none`}
                        >
                          Confirm
                        </button>

                        <button
                          onClick={() => togglePrecloseState(loan._id)}
                          className={`${themes[currentTheme].colors.error} ${themes[currentTheme].colors.errorHover} ${themes[currentTheme].colors.buttonText} py-2 px-6 rounded-md focus:outline-none`}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {deleteStates[loan._id] && (
                  <div
                    className={`absolute inset-0 h-full w-full ${themes[currentTheme].colors.card} bg-opacity-50 flex justify-center items-center`}
                  >
                    <div
                      className={`${themes[currentTheme].colors.innerBackground}  p-4 px-8 rounded-md`}
                    >
                      <div className="mb-2">
                        Are you sure you want to delete this loan?
                      </div>
                      <div className="flex justify-center gap-3">
                        <button
                          onClick={() => handleDelete(loan._id)}
                          className={`${themes[currentTheme].colors.error} ${themes[currentTheme].colors.errorHover} ${themes[currentTheme].colors.buttonText} py-2 px-6 rounded-md focus:outline-none`}
                        >
                          Delete
                        </button>

                        <button
                          onClick={() => toggleDeleteState(loan._id)}
                          className={`${themes[currentTheme].colors.error} ${themes[currentTheme].colors.errorHover} ${themes[currentTheme].colors.buttonText} py-2 px-6 rounded-md focus:outline-none`}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>
        );
      case "tile":
        return (
          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4">
            {loans.map((loan) => (
              <div
                key={loan._id}
                className={`p-3 relative ${themes[currentTheme].colors.card} rounded-md shadow-md`}
              >
                <div className="flex flex-col gap-2">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center gap-2 font-semibold">
                      {loanTypeIcons[loan.loanType] &&
                        loanTypeIcons[loan.loanType]({ fontSize: "22px" })}
                      <p className="text-2xl">{loan.lender}</p>
                    </div>
                    <div>
                      <LuMoreVertical
                        onClick={() => toggleOptions(loan._id)}
                        className="cursor-pointer p-2 text-4xl"
                      />
                    </div>
                  </div>
                  <p>
                    {loan.loanType.charAt(0).toUpperCase() +
                      loan.loanType.slice(1)}{" "}
                    Loan
                  </p>
                  <p>Amount: ₹ {loan.loanAmount}</p>
                  <p>Interest Rate: {loan.interestRate.$numberDecimal} %</p>
                  <p>Monthly EMI: ₹ {loan.monthlyEMI}</p>

                  <p>
                    Loan Status:
                    <span className={`p-1 ${getStatusColor(loan.loanStatus)}`}>
                      {loan.loanStatus.charAt(0).toUpperCase() +
                        loan.loanStatus.slice(1)}
                    </span>
                  </p>

                  <div className="flex justify-between items-end">
                    <h2 className="text-xl w-1/2 font-semibold mb-2">
                      Loan Payment Progress
                    </h2>
                    <h2 className="text-xl w-1/2 font-semibold mb-2">{`${
                      loan.paidEmis
                    } / ${loan.paidEmis + loan.remainingEmis} EMIs paid`}</h2>
                  </div>
                  <div
                    style={{
                      height: "1rem",
                      backgroundColor: `${themes[currentTheme].colors.spare}`,
                      borderRadius: "0.5rem",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        height: "100%",
                        width: `${(
                          (loan.paidEmis /
                            (loan.paidEmis + loan.remainingEmis)) *
                          100
                        ).toFixed(2)}%`,
                        backgroundColor: `${themes[currentTheme].colors.loader}`,
                        transition: "width 0.3s ease-in-out",
                      }}
                    ></div>
                  </div>
                </div>

                {showOptions[loan._id] && (
                  <div className="absolute right-12 top-3 flex flex-col justify-center mt-2">
                    <Link
                      to={`/loans/${loan._id}`}
                      className={`px-2 py-1 w-36  text-center rounded-t-lg ${themes[currentTheme].colors.innerBackground} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHoverText} hover:font-semibold active:scale-95 cursor-pointer`}
                    >
                      View Details
                    </Link>
                    <Link
                      to={`/edit-loan/${loan._id}`}
                      className={`px-2 py-1 w-36  text-center ${themes[currentTheme].colors.innerBackground} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHoverText} hover:font-semibold active:scale-95 cursor-pointer`}
                    >
                      Edit Loan
                    </Link>
                    <div
                      onClick={() => toggleDeleteState(loan._id)}
                      className={`px-2 py-1 w-36  text-center ${themes[currentTheme].colors.innerBackground} ${themes[currentTheme].colors.errorHover} ${themes[currentTheme].colors.buttonHoverText} hover:font-semibold active:scale-95 cursor-pointer`}
                    >
                      Delete
                    </div>
                    {loan.loanStatus !== "closed" && (
                      <div
                        onClick={() => togglePrecloseState(loan._id)}
                        className={`px-2 py-1 w-36  text-center rounded-b-lg ${themes[currentTheme].colors.innerBackground} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHoverText} hover:font-semibold active:scale-95 cursor-pointer`}
                      >
                        Settle Loan Early
                      </div>
                    )}
                  </div>
                )}

                {precloseStates[loan._id] && (
                  <div
                    className={`absolute inset-0 h-full w-full ${themes[currentTheme].colors.card} bg-opacity-50 flex justify-center items-center`}
                  >
                    <div
                      className={`${themes[currentTheme].colors.innerBackground}  p-4 px-8  rounded-md`}
                    >
                      <div className="mb-2">
                        Are you sure you want to preclose this loan?
                      </div>
                      <div className="flex justify-center gap-3">
                        <button
                          onClick={() => handlePreclosure(loan._id)}
                          className={`${themes[currentTheme].colors.error} ${themes[currentTheme].colors.errorHover} ${themes[currentTheme].colors.buttonText} py-2 px-6 rounded-md focus:outline-none`}
                        >
                          Confirm
                        </button>

                        <button
                          onClick={() => togglePrecloseState(loan._id)}
                          className={`${themes[currentTheme].colors.error} ${themes[currentTheme].colors.errorHover} ${themes[currentTheme].colors.buttonText} py-2 px-6 rounded-md focus:outline-none`}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {deleteStates[loan._id] && (
                  <div
                    className={`absolute inset-0 h-full w-full ${themes[currentTheme].colors.card} bg-opacity-50 flex justify-center items-center`}
                  >
                    <div
                      className={`${themes[currentTheme].colors.innerBackground}  p-4 px-8 rounded-md`}
                    >
                      <div className="mb-2">
                        Are you sure you want to delete this loan?
                      </div>
                      <div className="flex justify-center gap-3">
                        <button
                          onClick={() => handleDelete(loan._id)}
                          className={`${themes[currentTheme].colors.error} ${themes[currentTheme].colors.errorHover} ${themes[currentTheme].colors.buttonText} py-2 px-6 rounded-md focus:outline-none`}
                        >
                          Delete
                        </button>

                        <button
                          onClick={() => toggleDeleteState(loan._id)}
                          className={`${themes[currentTheme].colors.error} ${themes[currentTheme].colors.errorHover} ${themes[currentTheme].colors.buttonText} py-2 px-6 rounded-md focus:outline-none`}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  const handlePreclosure = async (id) => {
    try {
      await axios.post(`${API_BASE_URL}/loans/preclosure/${id}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      navigate(`/loans/${id}`);
    } catch (error) {
      console.error("Error in preclosure:", error.message);
    }
  };

  const fetchLoans = async () => {
    try {
      const response = await axios.get(`${allLoansAPI}/all-loans`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOriginalLoans(response.data);
      setLoading(false);
      console.log(response.data, "Response");
    } catch (error) {
      setLoading(false);
      console.error("Error fetching loans:", error.message);
    }
  };

  const filterAndSortLoans = () => {
    let filteredLoans = [...originalLoans];

    // Apply filtering
    if (loanStatusFilter) {
      filteredLoans = filteredLoans.filter(
        (loan) => loan.loanStatus === loanStatusFilter
      );
    }
    if (loanTypeFilter) {
      filteredLoans = filteredLoans.filter(
        (loan) => loan.loanType === loanTypeFilter
      );
    }
    filteredLoans.sort((a, b) => {
      switch (sortCriteria) {
        case "loanTakenDate":
          return sortOrder === "asc"
            ? new Date(a.loanStartDate) - new Date(b.loanStartDate)
            : new Date(b.loanStartDate) - new Date(a.loanStartDate);
        case "emiAmount":
          return sortOrder === "asc"
            ? a.monthlyEMI - b.monthlyEMI
            : b.monthlyEMI - a.monthlyEMI;
        case "interestRate":
          return sortOrder === "asc"
            ? parseFloat(a.interestRate.$numberDecimal) -
                parseFloat(b.interestRate.$numberDecimal)
            : parseFloat(b.interestRate.$numberDecimal) -
                parseFloat(a.interestRate.$numberDecimal);
        case "loanAmount":
          return sortOrder === "asc"
            ? a.loanAmount - b.loanAmount
            : b.loanAmount - a.loanAmount;
        case "remainingEmis":
          return sortOrder === "asc"
            ? a.remainingEmis - b.remainingEmis
            : b.remainingEmis - a.remainingEmis;
        default:
        case "createdAt":
          return sortOrder === "asc"
            ? new Date(a.createdAt) - new Date(b.createdAt)
            : new Date(b.createdAt) - new Date(a.createdAt);
      }
    });

    setLoans(filteredLoans);
    console.log("filtered", filteredLoans);
  };

  useEffect(() => {
    fetchLoans();
  }, [token]);

  useEffect(() => {
    filterAndSortLoans();
  }, [loading, loanStatusFilter, loanTypeFilter, sortCriteria, sortOrder]);

  const handleDelete = async (loanId) => {
    try {
      await axios.delete(`${API_BASE_URL}/loans/${loanId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      fetchLoans();
    } catch (error) {
      console.error("Error deleting loan:", error.message);
    }
  };

  const handleFilterChange = (filterType, value) => {
    if (filterType === "loanStatus") {
      setLoanStatusFilter(value);
      setLoanTypeFilter(null);
    } else if (filterType === "loanType") {
      setLoanTypeFilter(value);
      setLoanStatusFilter(null);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "active":
        return `${themes[currentTheme].colors.accentText}`;
      case "closed":
        return `${themes[currentTheme].colors.textLight}`;
      case "overdue":
        return `${themes[currentTheme].colors.errorText}`;
      default:
        return `${themes[currentTheme].colors.text}`;
    }
  };

  const loanTypeIcons = {
    personal: AiOutlineUser,
    consumer: AiOutlineUser,
    gold: AiOutlineGold,
    car: AiOutlineCar,
    home: AiOutlineHome,
    business: AiOutlineUser,
    "credit card": AiOutlineCreditCard,
    bike: AiOutlineUser,
    others: AiOutlineUser,
  };

  return (
    <div
      className={`p-5 h-screen overflow-auto ${themes[currentTheme].colors.pageBackground}`}
    >
      <h2 className="text-2xl font-bold mb-4">Loans List</h2>

      {/* Filter dropdowns */}
      <div className="flex flex-col xl:flex-row justify-between items-center mb-4 gap-4 xl:gap-0">
        <div className="flex items-center justify-center gap-2">
          <div>
            <label className="mr-2">Loan Type:</label>
            <select
              onChange={(e) => handleFilterChange("loanType", e.target.value)}
              value={loanTypeFilter || ""}
              className={`rounded-lg ${themes[currentTheme].colors.card} p-2`}
            >
              <option value="">All</option>
              <option value="personal">Personal Loans</option>
              <option value="consumer">Consumer Loans</option>
              <option value="gold">Gold Loans</option>
              <option value="car">Car Loans</option>
              <option value="home">Home Loans</option>
              <option value="business">Business Loans</option>
              <option value="credit card">Credit Card Loans</option>
              <option value="bike">Bike Loans</option>
              <option value="others">Other Loans</option>
            </select>
          </div>

          <div>
            <label className="mr-2">Loan Status:</label>
            <select
              onChange={(e) => handleFilterChange("loanStatus", e.target.value)}
              value={loanStatusFilter || ""}
              className={`rounded-lg ${themes[currentTheme].colors.card} p-2`}
            >
              <option value="">All</option>
              <option value="active">Active Loans</option>
              <option value="closed">Closed Loans</option>
              <option value="overdue">Overdue Loans</option>
            </select>
          </div>
        </div>
        <div className="flex items-center justify-center gap-2">
          {/* Sorting dropdown */}
          <div>
            <label className="mr-2">Sort By:</label>
            <select
              onChange={(e) => setSortCriteria(e.target.value)}
              value={sortCriteria || ""}
              className={`rounded-lg ${themes[currentTheme].colors.card} p-2`}
            >
              <option value="createdAt">Created Date</option>
              <option value="loanTakenDate">Loan Taken Date</option>
              <option value="emiAmount">EMI Amount</option>
              <option value="interestRate">Interest Rate</option>
              <option value="loanAmount">Loan Amount</option>
              <option value="remainingEmis">Remaining EMIs</option>
            </select>
          </div>

          {/* Order toggle */}
          <div>
            <label className="mr-2">Sort Order:</label>
            <select
              onChange={(e) => setSortOrder(e.target.value)}
              value={sortOrder}
              className={`rounded-lg ${themes[currentTheme].colors.card} p-2`}
            >
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </select>
          </div>
        </div>
        <div className="flex items-center justify-center gap-2">
          <div
            onClick={() => {
              setViewMode("tile");
            }}
            className={`${themes[currentTheme].colors.accentText} cursor-pointer hover:scale-125 transition-all duration-300`}
          >
            <MdOutlineGridView fontSize={24} />
          </div>
          <div
            onClick={() => {
              setViewMode("card");
            }}
            className={`${themes[currentTheme].colors.accentText} cursor-pointer hover:scale-125 transition-all duration-300`}
          >
            <MdCalendarViewDay fontSize={24} />
          </div>
          <div
            onClick={() => {
              setViewMode("table");
            }}
            className={`${themes[currentTheme].colors.accentText} cursor-pointer hover:scale-125 transition-all duration-300`}
          >
            <MdTableChart fontSize={24} />
          </div>
        </div>
      </div>

      {loanStatusFilter && loans.length === 0 && (
        <div>No matching loans found.</div>
      )}

      {loanTypeFilter && loans.length === 0 && (
        <div>No matching loans found.</div>
      )}

      {/* Loan list */}
      {loading ? (
        <div
          className={`flex justify-center items-center flex-col h-96 ${themes[currentTheme].colors.accentText}`}
        >
          <div className="h-8">
            <PropagateLoader
              color={`${themes[currentTheme].colors.loader}`}
              size={15}
              margin={2}
            />
          </div>
          <p className="text-center">
            Gathering your financial details.
            <br />
            Your complete loan history is on its way!
          </p>
        </div>
      ) : (
        <div className="flex-grow overflow-y-auto">
          {loans ? (
            renderLoanList()
          ) : (
            <div className="h-96 w-full flex flex-col justify-center items-center">
              <p className="text-2xl mb-6">
                It seems there are currently no loans.
              </p>
              <Link to="/add">
                <button
                  className={`${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonText} font-semibold  py-2 px-4 rounded-md focus:outline-none`}
                >
                  Add Loan
                </button>
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default LoanList;
