const themes = {
  harmony: {
    colors: {
      card: "bg-teal-50",
      button: "bg-teal-500",
      buttonText: "text-white",
      buttonHoverText: "hover:text-white",
      buttonHover: "hover:bg-teal-600",
      accentText: "text-teal-600",
      text: "text-gray-700",
      textLight: "text-gray-500",
      textDark: "text-gray-900",
      sidenavBackground: "bg-teal-100",
      secondaryBackground: "bg-teal-600",
      pageBackground: "bg-white",
      innerBackground: "bg-white",
      loader: "#14b8a6",
      input: "bg-teal-100",
      inputText: "text-gray-700",
      spare: "#ccfbf1",
      errorText: "text-red-600",
      error: "bg-red-500",
      errorHover: "hover:bg-red-600",
    },
    docs: {
      title: "#0f766e",
      background: "#0d9488",
      secondaryBackground: "#ccfbf1",
      text: "#1f2937",
      heading: "#f0fdfa",
    },
    charts: {
      accent1: "#ccfbf1",
      accent2: "#99f6e4",
      accent3: "#5eead4",
      accent4: "#2dd4bf",
      accent5: "#14b8a6",
      accent6: "#0d9488",
      accent7: "#0f766e",
    },
  },
  elegant: {
    colors: {
      card: "bg-indigo-50",
      button: "bg-indigo-500",
      buttonText: "text-white",
      buttonHoverText: "hover:text-white",
      buttonHover: "hover:bg-indigo-600",
      accentText: "text-indigo-600",
      text: "text-gray-700",
      textLight: "text-gray-500",
      textDark: "text-gray-900",
      sidenavBackground: "bg-indigo-100",
      secondaryBackground: "bg-indigo-600",
      pageBackground: "bg-white",
      innerBackground: "bg-white",
      loader: "#6366f1",
      input: "bg-indigo-100",
      inputText: "text-gray-700",
      spare: "#e0e7ff",
      errorText: "text-red-600",
      error: "bg-red-500",
      errorHover: "hover:bg-red-600",
    },
    docs: {
      title: "#4338ca",
      background: "#4f46e5",
      secondaryBackground: "#e0e7ff",
      text: "#1f2937",
      heading: "#eef2ff",
    },
    charts: {
      accent1: "#e0e7ff",
      accent2: "#c7d2fe",
      accent3: "#a5b4fc",
      accent4: "#818cf8",
      accent5: "#6366f1",
      accent6: "#4f46e5",
      accent7: "#4338ca",
    },
  },
  trust: {
    colors: {
      card: "bg-cyan-600",
      button: "bg-cyan-700",
      buttonText: "text-white",
      buttonHoverText: "hover:text-white",
      buttonHover: "hover:bg-cyan-600",
      accentText: "text-cyan-200",
      text: "text-slate-50",
      textDark: "text-slate-300",
      textLight: "text-slate-100",
      sidenavBackground: "bg-cyan-900",
      secondaryBackground: "bg-cyan-600",
      pageBackground: "bg-cyan-800",
      innerBackground: "bg-cyan-900",
      loader: "#fff",
      input: "bg-cyan-100",
      inputText: "text-gray-900",
      spare: "#67e8f9",
      errorText: "text-red-600",
      error: "bg-red-500",
      errorHover: "hover:bg-red-600",
    },
    docs: {
      title: "#0e7490",
      background: "#0891b2",
      secondaryBackground: "#cffafe",
      text: "#1f2937",
      heading: "#ecfeff",
    },
    charts: {
      accent1: "#cffafe",
      accent2: "#a5f3fc",
      accent3: "#67e8f9",
      accent4: "#22d3ee",
      accent5: "#06b6d4",
      accent6: "#0891b2",
      accent7: "#0e7490",
    },
  },

  growth: {
    colors: {
      card: "bg-emerald-800",
      button: "bg-emerald-500",
      buttonText: "text-white",
      buttonHoverText: "hover:text-white",
      buttonHover: "hover:bg-emerald-700",
      accentText: "text-emerald-200",
      text: "text-slate-50",
      textDark: "text-slate-300",
      textLight: "text-slate-100",
      sidenavBackground: "bg-emerald-900",
      secondaryBackground: "bg-emerald-600",
      pageBackground: "bg-emerald-600",
      innerBackground: "bg-emerald-900",
      loader: "#fff",
      input: "bg-emerald-100",
      inputText: "text-gray-900",
      spare: "#34d399",
      errorText: "text-red-600",
      error: "bg-red-500",
      errorHover: "hover:bg-red-600",
    },
    docs: {
      title: "#047857",
      background: "#059669",
      secondaryBackground: "#d1fae5",
      text: "#1f2937",
      heading: "#ecfdf5",
    },
    charts: {
      accent1: "#d1fae5",
      accent2: "#a7f3d0",
      accent3: "#6ee7b7",
      accent4: "#34d399",
      accent5: "#10b981",
      accent6: "#059669",
      accent7: "#047857",
    },
  },
  classicLight: {
    colors: {
      card: "bg-white",
      button: "bg-gray-200",
      buttonText: "text-gray-700",
      buttonHoverText: "hover:text-gray-700",
      buttonHover: "hover:bg-gray-300",
      accentText: "text-gray-700",
      text: "text-gray-500",
      textLight: "text-gray-400",
      textDark: "text-gray-900",
      sidenavBackground: "bg-gray-100",
      secondaryBackground: "bg-gray-300",
      pageBackground: "bg-white",
      innerBackground: "bg-gray-100",
      loader: "#6c757d",
      input: "bg-gray-100",
      inputText: "text-gray-700",
      spare: "#e5e7eb",
      errorText: "text-red-600",
      error: "bg-red-500",
      errorHover: "hover:bg-red-600",
    },
    docs: {
      title: "#374151",
      background: "#4b5563",
      secondaryBackground: "#f3f4f6",
      text: "#1f2937",
      heading: "#f9fafb",
    },
    charts: {
      accent1: "#f3f4f6",
      accent2: "#e5e7eb",
      accent3: "#d1d5db",
      accent4: "#9ca3af",
      accent5: "#6b7280",
      accent6: "#4b5563",
      accent7: "#374151",
    },
  },
  classicDark: {
    colors: {
      card: "bg-gray-700",
      button: "bg-gray-600",
      buttonText: "text-white",
      buttonHoverText: "hover:text-white",
      buttonHover: "hover:bg-gray-500",
      accentText: "text-gray-200",
      text: "text-gray-300",
      textLight: "text-gray-400",
      textDark: "text-white",
      sidenavBackground: "bg-gray-900",
      secondaryBackground: "bg-gray-600",
      pageBackground: "bg-gray-800",
      innerBackground: "bg-gray-900",
      loader: "#fff",
      input: "bg-gray-100",
      inputText: "text-gray-700",
      spare: "#6b7280",
      errorText: "text-red-600",
      error: "bg-red-500",
      errorHover: "hover:bg-red-600",
    },
    docs: {
      title: "#374151",
      background: "#4b5563",
      secondaryBackground: "#f3f4f6",
      text: "#1f2937",
      heading: "#f9fafb",
    },
    charts: {
      accent1: "#f3f4f6",
      accent2: "#e5e7eb",
      accent3: "#d1d5db",
      accent4: "#9ca3af",
      accent5: "#6b7280",
      accent6: "#4b5563",
      accent7: "#374151",
    },
  },
};

export default themes;
