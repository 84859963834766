import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { PropagateLoader } from "react-spinners";
import themes from "../../utils/themes";
import { API_BASE_URL } from "../../utils/apiConfig";
import { useAuth } from "../../contexts/AuthContext";
import { useSelector } from "react-redux";

const DTIRatio = ({ cardHeadingCss, cardContentCss, dashboardData }) => {
  const { token, userId } = useAuth();
  const [DTIRatio, setDTIRatio] = useState("");
  const [userIncome, setUserIncome] = useState(0);
  const currentTheme = useSelector((state) => state.theme);

  const getUser = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setUserIncome(response.data.income);
    } catch (error) {
      console.error(error.response.data.error);
    }
  };
  useEffect(() => {
    getUser();
  }, [userId, token]);

  const calculateDTIRatio = () => {
    const ratio = (dashboardData.totalEMIAmountCurrentMonth / userIncome) * 100;

    setDTIRatio(ratio);
  };

  useEffect(() => {
    calculateDTIRatio();
  }, [userIncome, dashboardData.totalEMIAmountCurrentMonth]);

  return (
    <div>
      <h3 className={cardHeadingCss}>Debt To Income Ratio</h3>
      <p className={cardContentCss}>
        {userIncome ? (
          <p>{DTIRatio.toFixed(2)} %</p>
        ) : (
          <div>
            Please add your income to calculate the Debt-to-Income Ratio (DTI).
          </div>
        )}
      </p>
      <p>
        {userIncome && (
          <>
            {DTIRatio <= 36 ? (
              <div>
                <p className={`${themes[currentTheme].colors.accentText} my-2 font-semibold`}>
                  Good
                </p>
                <p>
                  A DTI of 36 or below is considered good, showing low debt
                  compared to income.
                </p>
              </div>
            ) : DTIRatio > 36 && DTIRatio <= 42 ? (
              <div>
                <p className={`${themes[currentTheme].colors.accentText} my-2 font-semibold`}>
                  Moderate
                </p>
                <p>
                  With a DTI above 36 but 42 or below, it's moderate, indicating
                  moderate debt levels.
                </p>
              </div>
            ) : (
              <div>
                <p className={`${themes[currentTheme].colors.errorText} my-2 font-semibold`}>
                  High
                </p>
                <p>
                  If your DTI exceeds 42, it's high, posing risks to financial
                  stability.
                </p>
              </div>
            )}
          </>
        )}
      </p>
    </div>
  );
};

export default DTIRatio;
