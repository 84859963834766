import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useSelector } from "react-redux";
import themes from "../utils/themes";
import { API_BASE_URL } from "../utils/apiConfig";

const EditLoan = () => {
  const { token } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    
  });
  const currentTheme = useSelector((state)=> state.theme)

  const [feedbackMessage, setFeedbackMessage] = useState("");

  useEffect(() => {
    const fetchLoanDetails = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/loans/loan-details/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const loanStartDate = new Date(response.data.loanStartDate)
          .toISOString()
          .split("T")[0];
        const interestRate = response.data.interestRate.$numberDecimal;
        setFormData({
          ...response.data,
          interestRate,
          loanStartDate,
        });
      } catch (error) {
        console.error("Error fetching loan details:", error.message);
      }
    };

    fetchLoanDetails();
  }, [id, token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.patch(
        `${API_BASE_URL}/loans/${id}/edit`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Loan updated successfully:", response.data);
      setFeedbackMessage("Loan updated successfully");
      navigate("/loans", {
        replace: true,
        state: {
          from: location.pathname,
        },
      });
    } catch (error) {
      console.error("Error updating loan:", error.message);
      setFeedbackMessage("Error updating loan. Please try again.");
    }
  };
  const inputBoxStyle = `${themes[currentTheme].colors.input} ${themes[currentTheme].colors.inputText} mt-1 p-2 w-full rounded-md focus:outline-none focus:border-blue-500`
  return (
    <div className={`h-screen p-5 ${themes[currentTheme].colors.pageBackground}`}>
    <div className={`container mx-auto my-8 p-6 ${themes[currentTheme].colors.card} rounded-md shadow-md max-w-2xl`}>
      <h2 className="text-2xl font-bold mb-4">Edit Loan</h2>

      {feedbackMessage && (
        <p className={`${themes[currentTheme].colors.accentText} font-semibold mb-4`}>{feedbackMessage}</p>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {/* Loan Amount */}
        <div>
          <label className="block text-sm font-medium ">
            Loan Amount
          </label>
          <input
            type="number"
            name="loanAmount"
            value={formData.loanAmount}
            onChange={handleChange}
            required
            className={inputBoxStyle}
          />
        </div>

        {/* Loan Tenure */}
        <div>
          <label className="block text-sm font-medium ">
            Loan Tenure (in months)
          </label>
          <input
            type="number"
            name="loanTenure"
            value={formData.loanTenure}
            onChange={handleChange}
            required
            className={inputBoxStyle}
          />
        </div>

        {/* Interest Rate */}
        <div>
          <label className="block text-sm font-medium ">
            Interest Rate (in %)
          </label>
          <input
            type="number"
            name="interestRate"
            value={formData.interestRate}
            onChange={handleChange}
            required
            className={inputBoxStyle}
          />
        </div>

        {/* Charges */}
        <div>
          <label className="block text-sm font-medium ">
            Charges (if any)
          </label>
          <input
            type="number"
            name="charges"
            value={formData.charges}
            onChange={handleChange}
            required
            className={inputBoxStyle}
          />
        </div>

        {/* Lender/Bank */}
        <div>
          <label className="block text-sm font-medium ">
            Lender/Bank
          </label>
          <input
            type="text"
            name="lender"
            value={formData.lender}
            onChange={handleChange}
            required
            className={inputBoxStyle}
          />
        </div>

        {/* EMI Due Date */}
        <div>
          <label className="block text-sm font-medium ">
            EMI Due Date
          </label>
          <select
            name="emiDate"
            value={formData.emiDate}
            onChange={handleChange}
            required
            className={inputBoxStyle}
          >
            {Array.from({ length: 31 }, (_, i) => (
              <option key={i + 1} value={(i + 1).toString()}>
                {i + 1}
              </option>
            ))}
          </select>
        </div>

        {/* Loan Start Date */}
        <div>
          <label className="block text-sm font-medium ">
            Loan Start Date
          </label>
          <input
            type="date"
            name="loanStartDate"
            value={formData.loanStartDate}
            onChange={handleChange}
            required
            className={inputBoxStyle}
          />
        </div>

        {/* Loan Type */}
        <div>
          <label className="block text-sm font-medium ">
            Loan Type
          </label>
          <select
            type="text"
            name="loanType"
            value={formData.loanType}
            onChange={handleChange}
            required
            className={inputBoxStyle}
          >
            <option>Select One</option>
            <option value="personal">Personal Loan</option>
            <option value="consumer">Consumer Loan</option>
            <option value="gold">Gold Loan</option>
            <option value="car">Car Loan</option>
            <option value="home">Home Loan</option>
            <option value="business">Business Loan</option>
            <option value="credit card">Credit Card Loan</option>
            <option value="bike">Bike Loan</option>
            <option value="others">Others</option>
          </select>
        </div>

        {/* Note */}
        <div>
          <label className="block text-sm font-medium ">
            Add a Note
          </label>
          <input
            type="text"
            name="note"
            value={formData.note}
            onChange={handleChange}
            required
            className={inputBoxStyle}
          />
        </div>
      </div>

      {/* Edit Loan Button */}
      <button
        onClick={handleSubmit}
        className={`mt-6 ${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonHover} font-semibold ${themes[currentTheme].colors.buttonText} py-2 px-4 rounded-md focus:outline-none`}
      >
        Update Loan
      </button>
    </div>
    </div>
  );
};

export default EditLoan;
