import React, { useState, useEffect } from "react";
import axios from "axios";
import { allLoansAPI, insightsAPI } from "../utils/ListOfAPIs";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import themes from "../utils/themes";
import { PropagateLoader } from "react-spinners";
import { IoOpenOutline } from "react-icons/io5";
const PaymentHistory = () => {
  const { token, username, logout, userId } = useAuth();
  const [loading, setLoading] = useState(true);
  const currentTheme = useSelector((state) => state.theme);
  const [loans, setLoans] = useState([]);

  const fetchLoans = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${allLoansAPI}/all-loans`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const sortedLoans = response.data.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      setLoans(sortedLoans);
      setLoading(false);
      console.log(response.data, "Response");
    } catch (error) {
      setLoading(false);
      console.error("Error fetching loans:", error.message);
    }
  };

  useEffect(() => {
    fetchLoans();
  }, [token]);

  const formatDate = (dateString) => {
    const options = {
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  };

  return (
    <div
      className={`h-screen overflow-y-auto p-5 ${themes[currentTheme].colors.pageBackground}`}
    >
      <h2 className="text-2xl font-bold mb-4">Payment History</h2>

      {loading ? (
        <div
          className={`flex justify-center items-center flex-col h-96 ${themes[currentTheme].colors.accentText}`}
        >
          <div className="h-8">
            <PropagateLoader
              color={`${themes[currentTheme].colors.loader}`}
              size={15}
              margin={2}
            />
          </div>
          <p className="text-center">
            Your loan payment history is on its way!
          </p>
        </div>
      ) : (
        <div>
          {loans.length === 0 ? (
            <p className={`${themes[currentTheme].colors.textLight}`}>
              No payment records found. It seems you haven't made any payments
              yet.
            </p>
          ) : (
            <div
              className={`flex flex-col gap-2 ${themes[currentTheme].colors.card} py-3 rounded-md`}
            >
              {loans.map((loan) => (
                <div
                  key={loan._id}
                  className={` flex flex-col gap-2 py-3 rounded-md px-5`}
                >
                  <div className="flex items-center mb-4 gap-4">
                    <Link
                      to={`/loans/${loan._id}`}
                      className={`text-2xl font-bold hover:underline ${themes[currentTheme].colors.textLight}`}
                    >
                      {loan.lender},{" "}
                      {loan.loanType.charAt(0).toUpperCase() +
                        loan.loanType.slice(1)}{" "}
                      Loan, Amount: {loan.loanAmount}
                    </Link>
                    <Link to={`/loans/${loan._id}`} className="font-bold hover:scale-125 transition-all duration-300">
                      <IoOpenOutline
                        fontSize={22}
                        color={themes[currentTheme].colors.loader}
                      />
                    </Link>
                  </div>
                  {loan.emiData
                    .filter((emi) => emi.status === "paid" && emi.paymentDate)
                    .map((paidEmi, index) => (
                      <div
                        className={`${themes[currentTheme].colors.sidenavBackground} p-2 rounded-md flex items-center flex-grow gap-3 justify-between`}
                        key={index}
                      >
                        <p>
                          EMI Paid: ₹ {parseFloat(paidEmi.amount).toFixed(0)}{" "}
                        </p>

                        {/* <p>Paid on {paidEmi.lender}</p> */}
                        {/* <p>
                      Paid on{" "}
                      {paidEmi.loanType.charAt(0).toUpperCase() +
                        paidEmi.loanType.slice(1)}
                    </p> */}
                        <div
                          className={`flex gap-1 ${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonText} p-1 px-2 rounded-md`}
                        >
                          <p>
                            {paidEmi.status.charAt(0).toUpperCase() +
                              paidEmi.status.slice(1)}
                          </p>
                          <p>on {formatDate(paidEmi.paymentDate)}</p>
                        </div>
                      </div>
                    ))}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PaymentHistory;
