import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import { useSelector } from "react-redux";
import themes from "../utils/themes";
import { API_BASE_URL } from "../utils/apiConfig";
import { AiOutlineClose } from "react-icons/ai";
import { PropagateLoader } from "react-spinners";

const ChangePassword = ({ onSuccess }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [processing, setProcessing] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState(null);
  const { userId, username } = useAuth();
  const currentTheme = useSelector((state) => state.theme);

  const handleChangePassword = async () => {
    try {
      
      if (newPassword !== confirmNewPassword) {
        console.error("New password and confirm new password do not match");
        
        return;
      }
      setProcessing(true);
      
      const response = await axios.post(
        `${API_BASE_URL}/user/${userId}/change-password`,
        {
          currentPassword,
          newPassword,
        }
      );
      setCurrentPassword("");
      setNewPassword("");
      setConfirmNewPassword("");

      if (response.data.message) {
        setProcessing(false);
        setSuccessMessage(response.data.message);
      }

      console.log(response.data.message);

      setTimeout(() => {
        onSuccess();
        setSuccessMessage("");
      }, 3000);
      
    } catch (error) {
      console.error(error.response.data.error);
      setError(error.response.data.error);
      setProcessing(false);
    }
  };
  const inputBoxStyle = `${themes[currentTheme].colors.input} ${themes[currentTheme].colors.inputText} mt-1 p-2 w-full rounded-md focus:outline-none focus:border-blue-500`;

  return (
    <div
      className={`container mx-auto my-8 p-6 ${themes[currentTheme].colors.card} rounded-md shadow-md max-w-2xl`}
    >
      <div className="flex justify-between items-center mb-3">
        <h2
          className={` text-2xl ${themes[currentTheme].colors.text} font-bold`}
        >
          Change Password
        </h2>
        <div className="p-1 cursor-pointer" onClick={onSuccess}>
          <AiOutlineClose fontSize={30} />
        </div>
      </div>

      {processing && (
        <div
          className={`flex justify-center items-center flex-col h-96 ${themes[currentTheme].colors.accentText}`}
        >
          <div className="h-8">
            <PropagateLoader
              color={`${themes[currentTheme].colors.loader}`}
              size={15}
              margin={2}
            />
          </div>
          <p className="text-center">
            Strengthening your account with a new password.
          </p>
        </div>
      )}
      {!processing && successMessage && (
        <p
          className={`flex h-96 justify-center items-center font-semibold ${themes[currentTheme].colors.accentText}`}
        >
          {successMessage},
          <span className={`${themes[currentTheme].colors.accentText} ml-2`}>
            {username.charAt(0).toUpperCase() + username.slice(1)}
          </span>
          !
        </p>
      )}
      {!processing && !successMessage && (
        <>
          <div className="mb-4">
            <label htmlFor="currentPassword">Current Password:</label>
            <input
              type="password"
              id="currentPassword"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              className={inputBoxStyle}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="newPassword">New Password:</label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className={inputBoxStyle}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="confirmNewPassword">Confirm New Password:</label>
            <input
              type="password"
              id="confirmNewPassword"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              className={inputBoxStyle}
            />
          </div>
          <div className="flex justify-center">
            <button
              onClick={handleChangePassword}
              className={`${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonText} ${themes[currentTheme].colors.buttonHoverText} font-semibold py-2 px-4 rounded-md  focus:outline-none`}
            >
              Change Password
            </button>
          </div>
        </>
      )}
      {error && !successMessage && (
        <div
          className={`flex justify-center mt-4 ${themes[currentTheme].colors.errorText}`}
        >
          {error}
        </div>
      )}
    </div>
  );
};

export default ChangePassword;
