import React from "react";

import { Link } from "react-router-dom";
import Logo from "../assets/Logo.png";
import themes from "../utils/themes";
import { useSelector } from "react-redux";

const Logout = () => {
  const currentTheme = useSelector((state)=>state.theme)
  return (
    <div className={`${themes[currentTheme].colors.button} min-h-screen flex flex-col justify-between items-center px-4 py-16`}>
      <div className="flex flex-col justify-center mb-8">
        <div className="flex justify-center gap-2 items-center">
          <div>
            <img className="w-16" src={Logo} alt="logo" />
          </div>
          <div className={`text-3xl font-semibold ${themes[currentTheme].colors.accentText}`}>
            LoanHub
          </div>
        </div>
        <div className={`mt-4 font-semibold ${themes[currentTheme].colors.textLight}`}>
          You have been successfully logged out.
        </div>
      </div>
      <div
 
        className={`${themes[currentTheme].colors.button} rounded-lg shadow-md p-8 max-w-2xl mx-auto flex flex-col justify-center items-center gap-4`}
      >
        <div className="text-xl font-semibold ">
          Log in again to manage your loans.
        </div>
        <Link to="/login">
          <button className={`${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonText} py-2 px-4 rounded-md  focus:outline-none`}>
            Log In
          </button>
        </Link>
        <div className={`mt-2 flex flex-col gap-3 ${themes[currentTheme].colors.textLight} text-center`}>
          <p>Thank you for using LoanHub.</p>
          <p>
            If you have any questions, need assistance, or would like to share
            your feedback or suggestions, please contact our support team at <a href={`mailto:hello@sashankgl.xyz`}>hello@sashankgl.xyz</a>.
          </p>
        </div>
      </div>

      <div className={`mt-8 ${themes[currentTheme].colors.textLight}`}>
        © 2023 LoanHub. All rights reserved.
      </div>
    </div>
  );
};

export default Logout;
