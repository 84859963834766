import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTheme } from "../actions/themeActions";
import themes from "../utils/themes";
import { useAuth } from "../contexts/AuthContext";
import { AiOutlineClose } from "react-icons/ai";

const ChangeTheme = () => {
  const dispatch = useDispatch();
  const currentTheme = useSelector((state) => state.theme);
  const { username } = useAuth();
  const handleThemeChange = (themeName) => {
    dispatch(setTheme(themeName));
  };

  return (
    <div
      className={`flex flex-col mt-2 px-3 ${themes[currentTheme].colors.card} rounded-lg`}
    >
      <button
        onClick={() => handleThemeChange("harmony")}
        className={`p-2 rounded-md hover:opacity-90`}
      >
        <div className="flex justify-start items-center gap-4">
          <div className="w-8 h-8 rounded-md bg-teal-500" />
          <div>Harmony</div>
        </div>
      </button>

      <button
        onClick={() => handleThemeChange("elegant")}
        className={`p-2 rounded-md hover:opacity-90`}
      >
        <div className="flex justify-start items-center gap-4">
          <div className="w-8 h-8 rounded-md bg-indigo-500" />
          <div>Elegant</div>
        </div>
      </button>

      <button
        onClick={() => handleThemeChange("trust")}
        className={`p-2 rounded-md hover:opacity-90`}
      >
        <div className="flex justify-start items-center gap-4">
          <div className="w-8 h-8 rounded-md bg-cyan-500" />
          <div>Trust</div>
        </div>
      </button>

      <button
        onClick={() => handleThemeChange("growth")}
        className={`p-2 rounded-md hover:opacity-90`}
      >
        <div className="flex justify-start items-center gap-4">
          <div className="w-8 h-8 rounded-md bg-emerald-500" />
          <div>Growth</div>
        </div>
      </button>

      <button
        onClick={() => handleThemeChange("classicLight")}
        className={`p-2 rounded-md hover:opacity-90`}
      >
        <div className="flex justify-start items-center gap-4">
          <div className="w-8 h-8 rounded-md bg-white" />
          <div>Classic Light</div>
        </div>
      </button>

      <button
        onClick={() => handleThemeChange("classicDark")}
        className={`p-2 rounded-md hover:opacity-90`}
      >
        <div className="flex justify-start items-center gap-4">
          <div className="w-8 h-8 rounded-md bg-gray-500" />
          <div>Classic Dark</div>
        </div>
      </button>
    </div>
  );
};

export default ChangeTheme;
