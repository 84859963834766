import { FaFilePdf } from "react-icons/fa6";
import { SiMicrosoftexcel } from "react-icons/si";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import { MdEdit } from "react-icons/md";
import { PropagateLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import themes from "../utils/themes";
import ExcelJS from "exceljs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Logo from "../assets/Logo.png";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut, Pie } from "react-chartjs-2";
import { API_BASE_URL } from "../utils/apiConfig";
import LoanSpecificPaymentHistory from "../components/LoanSpecificPaymentHistory";

const LoanDetailsInfo = ({ label, value }) => (
  <p className="mb-2 ">
    {label}: <strong>{value}</strong>
  </p>
);

const LoanDetails = () => {
  const { id } = useParams();
  const [loan, setLoan] = useState(null);
  const { token } = useAuth();
  const [loading, setLoading] = useState(true);
  const [loadingEmiIndex, setLoadingEmiIndex] = useState(null);
  const [selectedEmis, setSelectedEmis] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const currentTheme = useSelector((state) => state.theme);
  const docThemes = themes[currentTheme].docs;
  ChartJS.register(ArcElement, Tooltip, Legend);
  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedEmis(
      selectAll
        ? []
        : Array.from({ length: loan.emiData.length }, (_, index) => index)
    );
  };

  useEffect(() => {
    const fetchLoanDetails = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${API_BASE_URL}/loans/loan-details/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setLoan(response.data);
        setLoading(false);
        console.log("Loan Details Fetched:", response.data);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching loan details:", error.message);
      }
    };

    fetchLoanDetails();
  }, [id]);

  const toggleEmiSelection = (index) => {
    if (selectAll) {
      setSelectAll(false);
      setSelectedEmis([]);
    } else if (selectedEmis.length === 0) {
      setSelectedEmis((prevSelected) =>
        prevSelected.includes(index)
          ? prevSelected.filter((i) => i !== index)
          : [...prevSelected, index]
      );
    } else {
      const firstSelected = selectedEmis[0];
      const lastSelected = selectedEmis[selectedEmis.length - 1];

      const start = Math.min(firstSelected, index);
      const end = Math.max(lastSelected, index);

      const range = Array.from(
        { length: end - start + 1 },
        (_, i) => start + i
      );
      setSelectedEmis(range);
    }
  };

  const outstandingInterest = loan?.outstandingInterest || 0;
  const totalOutstanding = loan?.totalOutstanding || 0;
  const isLoanClosed =
    loan?.remainingEmis === 0 && loan?.totalOutstanding === 0;

  let data;
  if (isLoanClosed) {
    data = {
      labels: ["Paid Interest", "Paid Principal"],
      datasets: [
        {
          data: [
            loan?.totalInterest || 0,
            loan?.loanAmount - loan?.totalInterest || 0,
          ],
          backgroundColor: [
            `${themes[currentTheme].charts.accent1}`,
            `${themes[currentTheme].charts.accent2}`,
            `${themes[currentTheme].charts.accent3}`,
            `${themes[currentTheme].charts.accent4}`,
            `${themes[currentTheme].charts.accent5}`,
            `${themes[currentTheme].charts.accent6}`,
            `${themes[currentTheme].charts.accent7}`,
          ],
        },
      ],
    };
  } else {
    data = {
      labels: ["Outstanding Interest", "Outstanding Principal"],
      datasets: [
        {
          data: [loan?.outstandingInterest || 0, loan?.totalOutstanding || 0],
          backgroundColor: [
            `${themes[currentTheme].charts.accent1}`,
            `${themes[currentTheme].charts.accent2}`,
            `${themes[currentTheme].charts.accent3}`,
            `${themes[currentTheme].charts.accent4}`,
            `${themes[currentTheme].charts.accent5}`,
            `${themes[currentTheme].charts.accent6}`,
            `${themes[currentTheme].charts.accent7}`,
          ],
        },
      ],
    };
  }

  const options = {
    plugins: {
      tooltip: {
        callbacks: {},
      },
      legend: {
        display: false,
        labels: {
          display: false,
        },
      },
    },
  };

  const payEmi = async (loanId, emiIndex) => {
    try {
      setLoadingEmiIndex(emiIndex);
      const response = await axios.patch(
        `${API_BASE_URL}/loans/${loanId}/pay-emi/${emiIndex}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data) {
        setLoan(response.data);
      }

      console.log("EMI marked as paid successfully");
      setLoadingEmiIndex(null);
    } catch (error) {
      console.error("Error marking EMI as paid:", error.message);
    }
  };

  const paySelectedEmis = async () => {
    try {
      setLoadingEmiIndex(selectedEmis);
      const response = await axios.patch(
        `${API_BASE_URL}/loans/${id}/pay-multiple-emis`,
        { emiIndices: selectedEmis },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data) {
        setLoan(response.data);
      }
      console.log(response.data);
      console.log("Selected EMIs marked as paid successfully");
      setSelectedEmis([]);
    } catch (error) {
      console.error("Error marking selected EMIs as paid:", error.message);
    } finally {
      setLoadingEmiIndex(null);
    }
  };

  const markEmiUnpaid = async (loanId, emiIndex) => {
    try {
      setLoadingEmiIndex(emiIndex);
      const response = await axios.patch(
        `${API_BASE_URL}/loans/${loanId}/mark-emi-unpaid/${emiIndex}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data) {
        setLoan(response.data);
      }
      console.log(response.data);
      console.log("EMI marked as unpaid successfully");
      setLoadingEmiIndex(null);
    } catch (error) {
      console.error("Error marking EMI as unpaid:", error.message);
    }
  };

  const markSelectedEmisUnpaid = async () => {
    try {
      setLoadingEmiIndex(selectedEmis);
      const response = await axios.patch(
        `${API_BASE_URL}/loans/${id}/mark-multiple-emis-unpaid`,
        { emiIndices: selectedEmis },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data) {
        setLoan(response.data);
      }
      console.log(response.data);
      console.log("Selected EMIs marked as unpaid successfully");
      setSelectedEmis([]);
    } catch (error) {
      console.error("Error marking selected EMIs as unpaid:", error.message);
    } finally {
      setLoadingEmiIndex(null);
    }
  };

  const formatDate = (dateString) => {
    const options = {
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  };

  const getOrdinalSuffix = (day) => {
    const j = day % 10;
    const k = day % 100;
    if (j === 1 && k !== 11) {
      return `${day}st`;
    }
    if (j === 2 && k !== 12) {
      return `${day}nd`;
    }
    if (j === 3 && k !== 13) {
      return `${day}rd`;
    }
    return `${day}th`;
  };

  const handleDownloadPDF = async () => {
    await downloadPDF(loan.emiData);
  };

  const handleDownloadExcel = async () => {
    await downloadXLSX(loan.emiData);
  };

  const downloadPDF = () => {
    if (!loan.emiData || loan.emiData.length === 0) {
      console.error("No data available for PDF generation.");
      return;
    }


    const pdf = new jsPDF();

    pdf.setFontSize(20);
    pdf.setTextColor(docThemes.title);

    
    const appNameX = 88;
    const appNameY = 25;
    const logoX = 68;
    const logoY = 12;
    const logoWidth = 18;
    const logoHeight = 18;

    pdf.text("LoanHub", appNameX, appNameY);
    pdf.addImage(Logo, "PNG", logoX, logoY, logoWidth, logoHeight);

    
    pdf.setTextColor(docThemes.text);

    
    const textBelowX = 60;
    const textBelowY = 40;

    pdf.text("Loan Repayment Schedule", textBelowX, textBelowY);

    const headers = [
      "Due Date",
      "Status",
      "EMI (INR)",
      "Interest (INR)",
      "Principal (INR)",
    ];
    
    const data = loan.emiData.map((emi) => [
      formatDate(emi.dueDate),
      emi.status,
      `${parseFloat(emi.amount).toFixed(0)}`,
      `${parseFloat(emi.interest).toFixed(0)}`,
      `${parseFloat(emi.principalPortion).toFixed(0)}`,
    ]);

    pdf.autoTable({
      head: [headers],
      body: data,
      startY: 50,
      theme: "grid",

      headStyles: {
        fontSize: 14,
        fillColor: docThemes.background,
        textColor: docThemes.heading,
      },

      
      styles: {
        fontSize: 14,
        textColor: docThemes.text,
        fillColor: docThemes.secondaryBackground,
        cellPadding: { top: 2, right: 5, bottom: 2, left: 5 },
        valign: "middle",
        halign: "center",
        cellBorder: "none",
      },

      
      margin: { top: 20, bottom: 20, left: 10, right: 10 },

      
      rowPageBreak: "avoid",
    });

    
    pdf.save("Loan Repayment Schedule.pdf");
  };

  const downloadXLSX = async () => {
    if (!loan.emiData || loan.emiData.length === 0) {
      console.error("No data available for Excel generation.");
      return;
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Repayment Schedule");

    const headerRow = worksheet.addRow([
      "Due Date",
      "Status",
      "EMI (INR)",
      "Interest (INR)",
      "Principal (INR)",
    ]);
    
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: docThemes.background },
      };
      cell.font = { color: { argb: docThemes.heading }, bold: true };
    });

    
    loan.emiData.forEach((emi) => {
      worksheet.addRow([
        formatDate(emi.dueDate),
        emi.status,
        parseFloat(emi.amount).toFixed(0),
        parseFloat(emi.interest).toFixed(0),
        parseFloat(emi.principalPortion).toFixed(0),
      ]);
    });

    
    worksheet.eachRow({ includeEmpty: false }, (row) => {
      row.eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: docThemes.secondaryBackground },
        };
        cell.font = { color: { argb: docThemes.text } };
      });
    });

    
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const fileName = "Repayment Schedule.xlsx";

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  return (
    <div
      className={`p-5 h-screen overflow-y-auto ${themes[currentTheme].colors.pageBackground}`}
    >
      <h2 className="text-2xl font-bold mb-4">Loan Details</h2>
      {loading ? (
        <div
          className={`flex justify-center items-center flex-col h-96 ${themes[currentTheme].colors.accentText}`}
        >
          <div className="h-8">
            <PropagateLoader
              color={`${themes[currentTheme].colors.loader}`}
              size={15}
              margin={2}
            />
          </div>
          <p className="text-center">Loading your Loan details with Care</p>
        </div>
      ) : (
        <>
          <div
            className={`relative p-6 rounded-md ${themes[currentTheme].colors.card} shadow-md `}
          >
            {/* Edit Icon */}

            <div
              className={`absolute right-0 top-0 m-1 p-1 cursor-pointer rounded-md ${themes[currentTheme].colors.accentText} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHoverText}`}
            >
              <Link to={`/edit-loan/${id}`}>
                <MdEdit fontSize={32} />
              </Link>
            </div>

            <div className="grid grid-cols-4">
              <div className="flex flex-col">
                <h2
                  className={`${themes[currentTheme].colors.accentText} text-2xl font-semibold mb-4`}
                >
                  Basic Information
                </h2>
                <div className="">
                  <LoanDetailsInfo
                    label="Loan Amount"
                    value={`₹${loan.loanAmount}`}
                  />
                  <LoanDetailsInfo
                    label="Monthly EMI"
                    value={`₹${loan.monthlyEMI}`}
                  />
                  <LoanDetailsInfo label="Lender" value={loan.lender} />
                  <LoanDetailsInfo
                    label="Loan Start Date"
                    value={formatDate(
                      new Date(loan.loanStartDate).toLocaleDateString()
                    )}
                  />
                  <LoanDetailsInfo
                    label="Loan Tenure"
                    value={loan.loanTenure + ` Months`}
                  />
                  <LoanDetailsInfo
                    label="Interest Rate"
                    value={`${loan.interestRate.$numberDecimal}%`}
                  />
                  <LoanDetailsInfo
                    label="EMI Date"
                    value={`${getOrdinalSuffix(loan.emiDate)} of every month`}
                  />
                  <LoanDetailsInfo
                    label="Loan Type"
                    value={
                      loan.loanType.charAt(0).toUpperCase() +
                      loan.loanType.slice(1)
                    }
                  />
                  <LoanDetailsInfo label="Note" value={loan.note} />
                </div>
              </div>

              <div className="flex flex-col">
                {" "}
                <h2
                  className={`${themes[currentTheme].colors.accentText} text-2xl font-semibold mb-4 mt-2`}
                >
                  Repayment Details
                </h2>
                <div className="">
                  <LoanDetailsInfo
                    label="Outstanding Loan Amount"
                    value={loan.totalOutstanding}
                  />
                  <LoanDetailsInfo
                    label="Total Interest Being Charged"
                    value={loan.totalInterest}
                  />
                  <LoanDetailsInfo
                    label="Outstanding Interest"
                    value={loan.outstandingInterest}
                  />
                  <LoanDetailsInfo label="Paid EMIs" value={loan.paidEmis} />

                  <LoanDetailsInfo
                    label="Remaining EMIs"
                    value={loan.remainingEmis}
                  />
                  <LoanDetailsInfo
                    label="Overdue EMIs"
                    value={loan.overdueEmis}
                  />
                  <LoanDetailsInfo
                    label="Loan Maturity"
                    value={formatDate(loan.loanMaturity)}
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <h2
                  className={`${themes[currentTheme].colors.accentText} text-2xl font-semibold mb-4 mt-2`}
                >
                  Loan Status
                </h2>
                <div className="">
                  <LoanDetailsInfo
                    label="Loan Status"
                    value={
                      loan.loanStatus.charAt(0).toUpperCase() +
                      loan.loanStatus.slice(1)
                    }
                  />
                  <p>
                    Explanation for Overdue EMIs: If possible, provide a brief
                    explanation or breakdown of why there are overdue EMIs. This
                    could include reasons such as missed payments, financial
                    difficulties, or any other relevant information.
                  </p>
                </div>
                <h2
                  className={`${themes[currentTheme].colors.accentText} text-2xl font-semibold mb-4 mt-2`}
                >
                  Charges
                </h2>
                <div className="">
                  <LoanDetailsInfo label="Charges" value={loan.charges} />
                </div>
              </div>

              <div className="flex flex-col">
                <h2
                  className={`${themes[currentTheme].colors.accentText} text-2xl font-semibold mb-4 mt-2`}
                >
                  Charts
                </h2>
                <div className="flex justify-center items-center h-64 p-2">
                  <Doughnut data={data} options={options} />
                </div>
              </div>
            </div>
          </div>

          {/* Animated EMI Data */}

          <div className="my-4">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-bold mb-4">Loan Payment Progress</h2>
              <h2 className="text-2xl font-bold mb-4">{`${loan.paidEmis} / ${
                loan.paidEmis + loan.remainingEmis
              } EMIs paid`}</h2>
            </div>
            <div
              style={{
                height: "2rem",
                backgroundColor: `${themes[currentTheme].colors.spare}`,
                borderRadius: "0.75rem",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: `${(
                    (loan.paidEmis / (loan.paidEmis + loan.remainingEmis)) *
                    100
                  ).toFixed(2)}%`,
                  backgroundColor: `${themes[currentTheme].colors.loader}`,
                  transition: "width 0.3s ease-in-out", 
                }}
              ></div>
            </div>
          </div>

          <h2 className="text-2xl font-bold mb-4">Repayment Schedule</h2>

          <div className={`${themes[currentTheme].colors.card} rounded-md`}>
            <div className="flex justify-between items-center px-4 pt-4">
              <div className="flex gap-3">
                {selectedEmis.length > 0 && (
                  <div className="">
                    <button
                      onClick={paySelectedEmis}
                      disabled={loadingEmiIndex !== null}
                      className={`${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonText} px-4 py-2 rounded-md`}
                    >
                      Pay Selected EMIs
                    </button>
                  </div>
                )}

                {selectedEmis.length > 0 && (
                  <div className="">
                    <button
                      onClick={markSelectedEmisUnpaid}
                      disabled={loadingEmiIndex !== null}
                      className={`${themes[currentTheme].colors.error} ${themes[currentTheme].colors.errorHover} ${themes[currentTheme].colors.buttonText} px-4 py-2 rounded-md`}
                    >
                      Mark Selected EMIs as Unpaid
                    </button>
                  </div>
                )}
              </div>
              <div className="flex gap-3">
                <button
                  onClick={handleDownloadPDF}
                  className={`${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonText} px-4 py-2 rounded-md flex items-center gap-2`}
                >
                  <FaFilePdf fontSize={24} />
                  Download PDF
                </button>

                <button
                  onClick={handleDownloadExcel}
                  className={`${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonText} px-4 py-2 rounded-md flex items-center gap-2`}
                >
                  <SiMicrosoftexcel fontSize={24} />
                  Download Excel
                </button>
              </div>
            </div>
            {/* Add a button to trigger payment */}

            {/* EMI Data with animation in table format */}
            <table className="mt-6 w-full text-center">
              <thead>
                <tr>
                  <th className="p-3">
                    {" "}
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={toggleSelectAll}
                    />
                  </th>
                  <th className="p-3">Due Date</th>
                  <th className="p-3">Status</th>
                  <th className="p-3">EMI</th>
                  <th className="p-3">Interest</th>
                  <th className="p-3">Principal</th>
                  <th className="p-3">OutStanding Balance</th>
                  <th className="p-3">Pay</th>
                </tr>
              </thead>
              <tbody>
                {loan.emiData.map((emi, index) => (
                  <tr
                    key={index}
                    className={` ${
                      selectedEmis.includes(index) ? "bg-opacity-80" : ""
                    }`}
                  >
                    <td className="p-3">
                      <input
                        type="checkbox"
                        checked={selectedEmis.includes(index)}
                        onChange={() => toggleEmiSelection(index)}
                      />
                    </td>
                    <td className="p-3">{formatDate(emi.dueDate)}</td>
                    <td className="p-3">{emi.status}</td>
                    <td className="p-3">
                      ₹ {parseFloat(emi.amount).toFixed(0)}
                    </td>
                    <td className="p-3">
                      ₹ {parseFloat(emi.interest).toFixed(0)}
                    </td>
                    <td className="p-3">
                      ₹ {parseFloat(emi.principalPortion).toFixed(0)}
                    </td>
                    <td className="p-3">
                      ₹ {parseFloat(emi.outstandingPrincipal).toFixed(0)}
                    </td>
                    <td className="p-3 flex justify-center">
                      {emi.status !== "paid" ? (
                        <div
                          onClick={() => payEmi(loan._id, index)}
                          disabled={loadingEmiIndex === index}
                        >
                          {loadingEmiIndex === index ? (
                            <div
                              className={`cursor-pointer w-36 p-1 px-3 ${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonText} rounded-lg`}
                            >
                              Processing...
                            </div>
                          ) : (
                            <div
                              className={`cursor-pointer w-36 p-1 px-3 ${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonText} rounded-lg`}
                            >
                              Pay
                            </div>
                          )}
                        </div>
                      ) : (
                        emi.status === "paid" && (
                          <div
                            onClick={() => markEmiUnpaid(loan._id, index)}
                            disabled={loadingEmiIndex === index}
                          >
                            {loadingEmiIndex === index ? (
                              <div
                                className={`cursor-pointer w-36 p-1 px-3 ${themes[currentTheme].colors.error} ${themes[currentTheme].colors.errorHover} ${themes[currentTheme].colors.buttonText} rounded-lg`}
                              >
                                Processing...
                              </div>
                            ) : (
                              <div
                                className={`cursor-pointer w-36 p-1 px-3 ${themes[currentTheme].colors.error} ${themes[currentTheme].colors.errorHover} ${themes[currentTheme].colors.buttonText} rounded-lg`}
                              >
                                Mark as Unpaid
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <LoanSpecificPaymentHistory loan={loan} />
        </>
      )}
    </div>
  );
};

export default LoanDetails;
