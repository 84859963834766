import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import themes from "../utils/themes";
import { API_BASE_URL } from "../utils/apiConfig";

const MonthlyInterestRate = () => {
  const initialFormData = {
    interestRate: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [interest, setInterest] = useState("");
  const [showFAQ, setShowFAQ] = useState(false);
  const currentTheme = useSelector((state)=> state.theme)

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (/^(?:\d{1,2}|100)$/.test(value) || value === "") {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }
  };

  const calculateMonthlyInterestRate = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/calculate/monthly-interest-rate`,
        formData
      );
      setInterest(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleFAQ = () => {
    setShowFAQ(!showFAQ);
  };

  return (
    <div className={`h-screen p-5 ${themes[currentTheme].colors.pageBackground}`}>
    <div className={`container mx-auto p-6 ${themes[currentTheme].colors.card} rounded-md shadow-md max-w-2xl`}>
      <h2 className="text-2xl font-bold mb-4">Calculate Monthly Interest Rate</h2>

      <div className="mb-4">
        <label className="block text-sm font-medium ">
          Interest Rate
        </label>
        <input
          type="number"
          name="interestRate"
          value={formData.interestRate}
          onChange={handleChange}
          required
          className={`mt-1 p-2 w-full rounded-md focus:outline-none ${themes[currentTheme].colors.input} ${themes[currentTheme].colors.inputText}`}
        />
      </div>

      <button
        onClick={calculateMonthlyInterestRate}
        className={`${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonText} font-semibold py-2 px-4 rounded-md focus:outline-none`}
      >
        Calculate Interest
      </button>

      {interest && (
        <div className="mt-4">
          <strong>Monthly Interest:</strong> {interest}
        </div>
      )}

      <div className="mt-8 border-t pt-4">
        <button
          onClick={toggleFAQ}
          className={`${themes[currentTheme].colors.accentText} hover:underline focus:outline-none`}
        >
          {showFAQ ? "Hide FAQs" : "Show FAQs"}
        </button>

        {showFAQ && (
          <div className="mt-4">
          <h3 className="text-xl font-bold mb-2">FAQs</h3>
          <p>
            <strong>Q: </strong> How is the monthly interest rate different from the annual interest rate?
            <br />
            <strong>A: </strong> The annual interest rate represents the interest charged over a year, while the monthly interest rate is the equivalent rate for a single month. Monthly rates are often used for loan calculations.
          </p>
          <p>
            <strong>Q: </strong> Can the compounding frequency affect the total interest paid?
            <br />
            <strong>A: </strong> Yes, the compounding frequency, such as monthly or annually, can impact the total interest paid. More frequent compounding generally results in higher overall interest.
          </p>
          <p>
            <strong>Q: </strong> Are there any penalties for prepaying a loan before the term ends?
            <br />
            <strong>A: </strong> Some loans may have prepayment penalties. It's crucial to check the terms of the loan agreement to understand any potential fees for paying off the loan early.
          </p>
          <p>
            <strong>Q: </strong> How does the loan duration affect the monthly interest payment?
            <br />
            <strong>A: </strong> A longer loan duration may result in lower monthly payments but could lead to higher total interest paid over the life of the loan. Shorter durations generally mean higher monthly payments but lower overall interest.
          </p>
          <p>
            <strong>Q: </strong> Is the monthly interest rate the only cost associated with a loan?
            <br />
            <strong>A: </strong> No, the monthly interest rate is just one component. Other costs, such as fees and charges, can contribute to the total cost of the loan. It's essential to consider all associated costs when evaluating a loan.
          </p>
        </div>
        )}
      </div>
    </div>
    </div>
  );
};

export default MonthlyInterestRate;
