import React, { useState, useEffect } from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import themes from "../../utils/themes";
import { useSelector } from "react-redux";
import { insightsAPI } from "../../utils/ListOfAPIs";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";
import { PropagateLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../utils/apiConfig";
ChartJS.register(ArcElement, Tooltip, Legend);

const EMIReport = ({
  cardHeadingCss,
  // cardContentCss,
  dashboardData,
  payNowEmi,
  emiLoadingStates,
  unpayNowEmi,
}) => {
  const { token } = useAuth();
  const [emiSummary, setEmiSummary] = useState(null);
  const [isEmiReportLoading, setIsEmiReportLoading] = useState(true);

  const fetchEmiSummary = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/loans/emi-summary`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEmiSummary(response.data);
      console.log("EMI Report", response.data);
      setIsEmiReportLoading(false);
    } catch (error) {
      setIsEmiReportLoading(false);
    }
  };

  useEffect(() => {
    fetchEmiSummary();
  }, []);

  const [mouseEnterMessage, setMouseEnterMessage] = useState(null);

  const handleMouseEnter = () => {
    const message = `${emiSummary?.paidEmis} / ${
      emiSummary?.paidEmis + emiSummary?.remainingEmis
    } EMIs paid`;
    setMouseEnterMessage(message);
  };

  const handleMouseLeave = () => {
    setMouseEnterMessage(null);
  };
  const currentTheme = useSelector((state) => state.theme);

  const formatDate = (dateString) => {
    const options = {
      month: "short",
      day: "numeric",
      // year: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  };
  const cardContentCss = `font-semibold ${themes[currentTheme].colors.accentText}`;

  return (
    <div className="flex h-full">
      {isEmiReportLoading ? (
        <div className="flex justify-center items-center w-full h-full">
          <PropagateLoader
            color={`${themes[currentTheme].colors.loader}`}
            size={15}
            margin={2}
          />
        </div>
      ) : (
        <div className="w-full flex flex-col justify-between gap-3">
          <div className="flex flex-col xl:flex-row justify-between items-center">
            <div className="w-full xl:w-1/2 flex flex-grow flex-col h-full">
              <h3 className={cardHeadingCss}>EMI Overview</h3>
              <div className="flex flex-col gap-2">
                <div className="grid grid-cols-2">
                  <p
                    className={`mb-1 ${themes[currentTheme].colors.textLight} flex flex-col items-center justify-center  border-r`}
                  >
                    <p className={`${cardContentCss} text-xl`}>
                      ₹ {dashboardData.totalEMIAmountCurrentMonth}
                    </p>
                    <p>this month</p>
                  </p>

                  <p
                    className={`mb-1 ${themes[currentTheme].colors.textLight} flex flex-col items-center justify-center`}
                  >
                    <p className={`${cardContentCss} text-xl`}>
                      ₹ {dashboardData.totalEMIAmountNextMonth}
                    </p>
                    next month
                  </p>
                </div>

                <div className="flex flex-col justify-center items-center">
                  <p className={`text-xl ${cardContentCss}`}>
                    {formatDate(emiSummary?.nextUnpaidEmiDate)}
                  </p>
                  <p
                    className={`mb-1 ${themes[currentTheme].colors.textLight}`}
                  >
                    Next Payment Due
                  </p>
                </div>
                <div className="grid grid-cols-2">
                  <p
                    className={`mb-1 ${themes[currentTheme].colors.textLight} flex flex-col items-center justify-center  border-r`}
                  >
                    <p className={`${cardContentCss} text-xl`}>
                      {emiSummary?.totalEmis}
                    </p>
                    <p>Total</p>
                  </p>

                  <p
                    className={`mb-1 ${themes[currentTheme].colors.textLight} flex flex-col items-center justify-center`}
                  >
                    <p className={`${cardContentCss} text-xl`}>
                      {emiSummary?.paidEmis}
                    </p>
                    Paid
                  </p>
                </div>
                <div className="grid grid-cols-2">
                  <p
                    className={`mb-1 ${themes[currentTheme].colors.textLight} flex flex-col items-center justify-center  border-r`}
                  >
                    <p className={`${cardContentCss} text-xl`}>
                      {emiSummary?.remainingEmis}
                    </p>
                    <p>Remaining</p>
                  </p>

                  <p
                    className={`mb-1 ${themes[currentTheme].colors.textLight} flex flex-col items-center justify-center`}
                  >
                    <p className={`${cardContentCss} text-xl`}>
                      {emiSummary?.overdueEmis}
                    </p>
                    Overdue
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full xl:w-1/2 flex flex-col max-h-72 overflow-y-auto">
              <h3 className={`${cardHeadingCss} mb-2`}>Current Month EMIs</h3>
              {dashboardData.currentMonthEMIs.every(
                (emi) => emi.emiData.status === "paid"
              ) ? (
                "EMIs cleared for the month. 🚀"
              ) : (
                <div className="">
                  {dashboardData.currentMonthEMIs
                    .sort((emi1, emi2) => {
                      // Sort by status, with 'paid' EMIs at the bottom
                      if (
                        emi1.emiData.status === "paid" &&
                        emi2.emiData.status !== "paid"
                      ) {
                        return 1;
                      }
                      if (
                        emi1.emiData.status !== "paid" &&
                        emi2.emiData.status === "paid"
                      ) {
                        return -1;
                      }
                      return 0;
                    })
                    .map((emi, index) => (
                      <div
                        className={`shadow-sm flex justify-between p-1 items-center mb-2 mr-2 `}
                      >
                        <Link
                          to={`/loans/${emi.loanId}`}
                          key={index}
                          className="py-1 w-80"
                        >
                          Due: ₹ {parseFloat(emi.emiData.amount).toFixed(0)},{" "}
                          {formatDate(emi.emiData.dueDate)}
                          {/* -
                          {/* {emi.emiData.lender} */}{" "}
                          {/* <span
                            className={
                              emi.status === "paid"
                                ? `${themes[currentTheme].colors.accentText}`
                                : ``
                            }
                          >
                            {emi.emiData.status.charAt(0).toUpperCase() +
                              emi.emiData.status.slice(1)}
                          </span> */}
                        </Link>

                        {emi.emiData.status !== "paid" ? (
                          <div
                            className="cursor-pointer"
                            onClick={() =>
                              payNowEmi(emi.loanId, emi.emiData._id)
                            }
                          >
                            {emiLoadingStates[emi.emiData._id] ? (
                              <div
                                className={`cursor-pointer w-40 text-center p-1 px-3 ${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonText} rounded-lg`}
                              >
                                Processing...
                              </div>
                            ) : (
                              <div
                                className={`cursor-pointer w-40 text-center p-1 px-3 ${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonText} rounded-lg`}
                              >
                                Pay
                              </div>
                            )}
                          </div>
                        ) : (
                          emi.emiData.status === "paid" && (
                            <div
                              className="cursor-pointer"
                              onClick={() =>
                                unpayNowEmi(emi.loanId, emi.emiData._id)
                              }
                            >
                              {emiLoadingStates[emi.emiData._id] ? (
                                <div
                                  className={`cursor-pointer w-40 text-center p-1 px-3 ${themes[currentTheme].colors.error} ${themes[currentTheme].colors.errorHover} ${themes[currentTheme].colors.buttonText} rounded-lg`}
                                >
                                  Processing...
                                </div>
                              ) : (
                                <div
                                  className={`cursor-pointer w-40 text-center p-1 px-3 ${themes[currentTheme].colors.error} ${themes[currentTheme].colors.errorHover} ${themes[currentTheme].colors.buttonText} rounded-lg`}
                                >
                                  Mark As Unpaid
                                </div>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    ))}
                </div>
              )}

              {/* Upcoming EMIs */}
              <h3 className={`${cardHeadingCss} my-2`}>Upcoming EMIs</h3>
              {dashboardData.upcomingEmiDetails.every(
                (emi) => emi.status !== "upcoming"
              ) ? (
                "EMI-free month ahead. 💰"
              ) : (
                <div className="">
                  {dashboardData.upcomingEmiDetails.map((emi, index) => (
                    <div
                      className={`shadow-sm flex justify-between p-1 items-center mb-2 mr-2`}
                    >
                      <Link
                        to={`/loans/${emi.loanId}`}
                        key={index}
                        className="py-1"
                      >
                         Due: ₹ {parseFloat(emi.amount).toFixed(0)},{" "}
                          {formatDate(emi.dueDate)}
                        {/* - {emi.lender} */}
                      </Link>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="w-full flex flex-col justify-center gap-4 items-center">
            <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className="w-full"
              style={{
                height: "1rem",
                backgroundColor: `${themes[currentTheme].colors.spare}`,
                borderRadius: "0.5rem",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: `${(
                    (emiSummary?.paidEmis /
                      (emiSummary?.paidEmis + emiSummary?.remainingEmis)) *
                    100
                  ).toFixed(2)}%`,
                  backgroundColor: `${themes[currentTheme].colors.loader}`,
                  transition: "width 0.3s ease-in-out",
                }}
              >
                {mouseEnterMessage && (
                  <p
                    className={`ml-4 ${themes[currentTheme].colors.buttonText} text-xs`}
                  >
                    {mouseEnterMessage}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EMIReport;
