import React, { useState, useEffect } from "react";

import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";
import themes from "../../utils/themes";
import { useSelector } from "react-redux";
import { insightsAPI } from "../../utils/ListOfAPIs";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";
import { API_BASE_URL } from "../../utils/apiConfig";
import { PropagateLoader } from "react-spinners";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend
);

const LoanOverview = ({
  cardHeadingCss,
  cardContentCss,
  dashboardData,
  payNowEmi,
  emiLoadingStates,
  unpayNowEmi,
}) => {
  const { token } = useAuth();
  const [emiSummary, setEmiSummary] = useState(null);
  const [isEmiReportLoading, setIsEmiReportLoading] = useState(true);

  const fetchEmiSummary = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/loans/emi-summary`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEmiSummary(response.data);
      console.log("EMI Report", response.data);
      setIsEmiReportLoading(false);
    } catch (error) {
      setIsEmiReportLoading(false);
    }
  };

  useEffect(() => {
    fetchEmiSummary();
  }, []);

  const currentTheme = useSelector((state) => state.theme);

  const chartData = [
    dashboardData.totalEMIPaid,
    dashboardData.totalOutstandingBalance,
  ];

  const loanTenureData = dashboardData.userLoans
    .map((loan) =>
      loan.loanStatus === "active"
        ? {
            lender: loan.lender,
            totalTenure: loan.loanTenure,
            remainingTenure: loan.remainingEmis,
          }
        : null
    )
    .filter(Boolean);

  const barChartData = {
    labels: loanTenureData.map((loan) => loan.lender),
    datasets: [
      {
        label: "Total Tenure",
        data: loanTenureData.map((loan) => loan.totalTenure),
        backgroundColor: themes[currentTheme].charts.accent3,
      },
      {
        label: "Remaining Tenure",
        data: loanTenureData.map((loan) => loan.remainingTenure),
        backgroundColor: themes[currentTheme].charts.accent4,
      },
    ],
  };

  const barChartOptions = {
    // indexAxis: 'y',
    scales: {
      x: {
        // stacked: true,
        display: false,
      },
      y: {
        // stacked: true,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
    },
  };

  const data = {
    labels: ["Total Payments", "Unsettled Amount"],
    datasets: [
      {
        data: chartData,
        backgroundColor: [
          //   `${themes[currentTheme].charts.accent1}`,
          //   `${themes[currentTheme].charts.accent2}`,
          `${themes[currentTheme].charts.accent3}`,
          `${themes[currentTheme].charts.accent4}`,
          `${themes[currentTheme].charts.accent5}`,
          `${themes[currentTheme].charts.accent6}`,
          `${themes[currentTheme].charts.accent7}`,
        ],
      },
    ],
  };

  const [isHovered, setIsHovered] = useState(false);

  const options = {
    plugins: {
      tooltip: {
        callbacks: {},
      },
      legend: {
        display: false,
        labels: {
          display: false,
        },
      },
    },
    events: ["mousemove", "mouseout"],
    onHover: (event, chartElement) => {
      if (chartElement && chartElement.length > 0) {
        setIsHovered(true);
      } else {
        setIsHovered(false);
      }
    },
  };
  return (
    <div className="flex h-full">
      {isEmiReportLoading ? (
        <div className="flex justify-center items-center w-full h-full">
          <PropagateLoader
            color={`${themes[currentTheme].colors.loader}`}
            size={15}
            margin={2}
          />
        </div>
      ) : (
        <div className="w-full flex flex-col">
          <h3 className={cardHeadingCss}>Loan Overview</h3>
          <div className="flex flex-col sm:flex-row">
            <div className="w-48 flex items-center justify-center relative">
              <Doughnut data={data} options={options} />
              <div
                className={`${
                  isHovered ? "invisible" : "visible"
                } absolute inset-0 flex flex-col items-center justify-center pointer-events-none`}
              >
                <div className={cardContentCss}>
                  {(
                    (dashboardData.totalEMIPaid /
                      (dashboardData.totalEMIPaid +
                        dashboardData.totalOutstandingBalance)) *
                    100
                  ).toFixed(2)}{" "}
                  %
                </div>
                <div className={`${themes[currentTheme].colors.textLight}`}>
                  paid
                </div>
              </div>
            </div>
            {/* <div className="w-2/4">
              <Bar data={barChartData} options={barChartOptions} />
            </div> */}
            <div className="w-3/4 flex flex-col justify-center gap-4 pl-8 items-center">
              <div className="w-full flex justify-between items-center">
                <p>Aggregate Principal: </p>
                <p className={cardContentCss}>
                  ₹ {dashboardData.totalLoanAmountActiveLoans}
                </p>
              </div>
              <div className="w-full flex justify-between items-center">
                <p>Principal with Interest: </p>
                <p className={cardContentCss}>
                  ₹ {dashboardData.totalLoanAmountWithInterest}
                </p>
              </div>
              <div className="w-full flex justify-between items-center">
                <p>Total Payments: </p>
                <p className={cardContentCss}>₹ {dashboardData.totalEMIPaid}</p>
              </div>
              <div className="w-full flex justify-between items-center">
                <p>Total interest paid: </p>
                <p className={cardContentCss}>
                  ₹ {dashboardData.totalInterestPaidAllLoans}
                </p>
              </div>
              <div className="w-full flex justify-between items-center">
                <p>Unsettled Amount: </p>
                <p className={cardContentCss}>
                  ₹ {dashboardData.totalOutstandingBalance}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoanOverview;
