import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
// import Logo from "../assets/Logo.png";
import LogoElement from "../assets/LogoElement";
import LoanHubLogo from "../assets/LoanHubLogo.svg";
import {
  TbLayoutSidebarRightCollapseFilled,
  TbLayoutSidebarLeftCollapseFilled,
} from "react-icons/tb";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { MdCalculate } from "react-icons/md";
import { MdSpaceDashboard, MdOutlineLibraryAdd } from "react-icons/md";
import { MdHistory } from "react-icons/md";
import { SiDocsdotrs } from "react-icons/si";
import { RiLogoutCircleLine } from "react-icons/ri";
import { MdOutlineFormatColorFill } from "react-icons/md";
import themes from "../utils/themes";
import { API_BASE_URL } from "../utils/apiConfig";
import ChangeTheme from "./ChangeTheme";

const Sidebar = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1024);
  const [isCollapsed, setIsCollapsed] = useState(isSmallScreen ? true : false);
  const [openCalculators, setOpenCalculators] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { username } = useAuth();

  const userProfilePicture = useSelector((state) => state.userProfilePicture);
  const currentTheme = useSelector((state) => state.theme);
  useEffect(() => {}, [userProfilePicture]);

  const toggleCollapse = () => {
    setOpenCalculators(false);
    setShowThemes(false)
    setIsCollapsed(!isCollapsed);
  };

  const [showThemes, setShowThemes] = useState(false);

  const handleLogout = async () => {
    logout();
  };

  const renderLabel = (label) => !isCollapsed && <p>{label}</p>;

  const DashboardIcon = <MdSpaceDashboard fontSize="40px" />;
  const AddLoanIcon = <MdOutlineLibraryAdd fontSize="40px" />;
  const AllLoansIcon = <SiDocsdotrs fontSize="40px" />;
  const PaymentHistoryIcon = <MdHistory fontSize="40px" />;
  const LogoutIcon = <RiLogoutCircleLine fontSize="40px" />;
  const ThemeIcon = <MdOutlineFormatColorFill fontSize="40px" />;
  const CalculatorIcon = <MdCalculate fontSize="40px" />;
  const MenuUp = <FaCaretUp fontSize="24px" />;
  const MenuDown = <FaCaretDown fontSize="24px" />;

  const iconStyle = "w-16 flex justify-center items-center";
  const [logoColor1, setLogoColor1] = useState("#2dd4bf");
  const [logoColor2, setLogoColor2] = useState("#0f766e");

  useEffect(() => {
    setLogoColor1(themes[currentTheme].charts.accent4);
    setLogoColor2(themes[currentTheme].charts.accent6);
  }, [currentTheme]);

  return (
    <div
      className={`${
        isCollapsed ? "w-20" : "w-60"
      } flex flex-col justify-between transition-width duration-300 h-screen font-semibold ${
        themes[currentTheme].colors.sidenavBackground
      } p-2`}
    >
      <div className="flex flex-col">
        {/* Logo */}

        <div className={`h-16 flex justify-start items-center`}>
          <div className="w-16">
            <LogoElement logoColor1={logoColor1} logoColor2={logoColor2} />
          </div>
          {!isCollapsed && (
            <Link
              to="/"
              className={`text-3xl  flex justify-center text-center ${themes[currentTheme].colors.accentText}`}
            >
              LoanHub
            </Link>
          )}
        </div>

        {/* Dashboard Icon */}
        <Link
          to="/"
          className={`h-16 flex justify-start items-center ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHoverText} hover:rounded-lg`}
        >
          <div className={iconStyle}>{DashboardIcon}</div>

          {renderLabel("Insights")}
        </Link>
        {/* Add Loan Icon */}
        <Link
          to="/add"
          className={`h-16 flex justify-start items-center ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHoverText} hover:rounded-lg`}
        >
          <div className={iconStyle}>{AddLoanIcon}</div>

          {renderLabel("Add Loan")}
        </Link>
        {/* All Loans Icon */}
        <Link
          to="/loans"
          className={`h-16 flex justify-start items-center ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHoverText} hover:rounded-lg`}
        >
          <div className={iconStyle}>{AllLoansIcon}</div>

          {renderLabel("All Loans")}
        </Link>
        <Link
          to="/payment-history"
          className={`h-16 flex justify-start items-center ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHoverText} hover:rounded-lg`}
        >
          <div className={iconStyle}>{PaymentHistoryIcon}</div>

          {renderLabel("Payment History")}
        </Link>
        {/* <div
          onClick={() => {
            setOpenCalculators(!openCalculators);
            setIsCollapsed(false);
          }}
          className={`h-16 flex justify-start items-center ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHoverText} hover:rounded-lg cursor-pointer transition-all duration-200`}
        >
          <div className={iconStyle}>{CalculatorIcon}</div>

          {renderLabel("Calculators")}
          {!isCollapsed && (
            <div className={iconStyle}>
              {openCalculators ? <div>{MenuUp}</div> : <div>{MenuDown}</div>}
            </div>
          )}
        </div> */}
        {openCalculators && (
          <div className="ml-8">
            <Link
              to="/calculate/monthly-interest-rate"
              className={`h-12 flex px-4 justify-start items-center ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHoverText} hover:rounded-lg`}
            >
              <div className="text-center">Monthly Interest Rate</div>
            </Link>
            <Link
              to="/calculate/prorated-interest"
              className={`h-12 flex px-4 justify-start items-center ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHoverText} hover:rounded-lg`}
            >
              <div className="text-center">Prorated Interest</div>
            </Link>
            <Link
              to="/calculate/repayment-schedule"
              className={`h-12 flex px-4 justify-start items-center ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHoverText} hover:rounded-lg`}
            >
              <div className="text-center">Repayment Schedule</div>
            </Link>
          </div>
        )}
      </div>

      <div>
        <div
          onClick={() => {
            setShowThemes(!showThemes);
            setIsCollapsed(false)
          }}
          className={`h-16 flex justify-start items-center cursor-pointer ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHoverText} hover:rounded-lg`}
        >
          <div className={iconStyle}>{ThemeIcon}</div>

          {renderLabel("Change Theme")}
        </div>
        {showThemes && <ChangeTheme />}

        <div
          onClick={toggleCollapse}
          className={`h-16 flex justify-start items-center cursor-pointer ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHoverText} hover:rounded-lg`}
        >
          <div className={iconStyle}>
            {isCollapsed ? (
              <TbLayoutSidebarRightCollapseFilled fontSize="40px" />
            ) : (
              <TbLayoutSidebarLeftCollapseFilled fontSize="40px" />
            )}
          </div>

          {renderLabel("Collapse")}
        </div>
        <Link
          to="/profile"
          className={`h-16 flex justify-start items-center ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHoverText} hover:rounded-lg`}
        >
          <div className="w-16 h-16 rounded-lg flex justify-center items-center text-4xl font-bold">
            {userProfilePicture.userProfilePicture ? (
              <img
                src={userProfilePicture.userProfilePicture}
                alt="User Profile Picture"
                className="w-12 h-12 rounded-full"
              />
            ) : (
              <div> {username?.charAt(0)}</div>
            )}
          </div>
          {renderLabel(username)}
        </Link>
        <Link
          reloadDocument
          onClick={handleLogout}
          className={`h-16 flex justify-start items-center ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHoverText} hover:rounded-lg`}
        >
          <div className={iconStyle}>{LogoutIcon}</div>
          {!isCollapsed && <p>Logout</p>}
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
