import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../utils/firebase";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";

import { PropagateLoader } from "react-spinners";
import { MdEdit } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { setUserProfilePicture } from "../actions/userActions";
import { setTheme } from "../actions/themeActions";
import themes from "../utils/themes";
import ChangePassword from "../components/ChangePassword";
import ChangeTheme from "../components/ChangeTheme";
import { API_BASE_URL } from "../utils/apiConfig";
import { insightsAPI } from "../utils/ListOfAPIs";

const UserProfile = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const [showOptions, setShowOptions] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [showThemes, setShowThemes] = useState(false);
  const [user, setUser] = useState();
  const [deletedMessage, setDeletedMessage] = useState(false);
  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };
  const formatDate = (dateString) => {
    const options = {
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  };
  const dispatch = useDispatch();

  const handleThemeChange = (themeName) => {
    dispatch(setTheme(themeName));
    console.log("Theme changed", themeName);
  };

  const userProfilePicture = useSelector((state) => state.userProfilePicture);

  const { username, email, token, userId } = useAuth();

  const getUser = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("User Data: ", response.data);

      setUser(response.data);

      if (editMode) {
        setUpdateDetails({
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          dateOfBirth: response.data.dateOfBirth,
          mobileNumber: response.data.mobileNumber,
          country: response.data.country,
          income: response.data.income,
        });
      }
    } catch (error) {
      console.error(error.response.data.error);
    }
  };
  useEffect(() => {
    getUser();
  }, [userId, token, editMode]);

  const toggleEditMode = () => {
    setEditMode(!editMode);
    if (!editMode) {
      getUser();
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);

  const handleUpload = async () => {
    try {
      if (!file) {
        console.error("No file selected");
        return;
      }

      const storageRef = ref(storage, `images/${userId}/profilePicture`);

      // Upload the file to Firebase Storage
      const snapshot = await uploadBytes(storageRef, file);

      // Get the download URL after the upload is complete
      const downloadURL = await getDownloadURL(snapshot.ref);

      // Use the downloadURL as needed (e.g., update profile picture)
      console.log("File available at", downloadURL);
      dispatch(setUserProfilePicture(downloadURL));
      setFile(null);
      setUploadProgress(0);

      setShowConfirmation(true);
      setTimeout(() => {
        setShowConfirmation(false);
      }, 3000);
    } catch (error) {
      console.error("Error uploading profile picture:", error);
    }
  };

  const deleteAvatar = async () => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/user/upload/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        dispatch(setUserProfilePicture(null));

        setShowConfirmation(true);
        setTimeout(() => {
          setShowConfirmation(false);
        }, 3000);
      } else {
        console.error("Error deleting avatar");
      }
    } catch (error) {
      console.error("Error deleting avatar:", error);
    }
  };
  const [updateDetails, setUpdateDetails] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    mobileNumber: "",
    country: "",
    income: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateDetails({
      ...updateDetails,
      [name]: value,
    });
  };

  const [userLoanData, setUserLoanData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${insightsAPI}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserLoanData(response.data);
        console.log("User Loan Data", response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 401) {
          } else {
            console.error("Error fetching dashboard data:", error.message);
          }
        } else {
          console.error(
            "Non-Axios error fetching dashboard data:",
            error.message
          );
        }
      }
    };
    fetchData();
  }, []);

  const handleDownloadData = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/user/download-data/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      // Create a download link and trigger a click event
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "User_Data.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading user data:", error);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setTimeout(() => {
        setDeletedMessage(true);
      }, 10000);

      localStorage.removeItem("token");
      console.log(response, "response delete");
      console.log(userId, "user ID");
      // Handle success if needed
      console.log("Account Deleted Successfully");
    } catch (error) {
      // Handle error
      console.error("Error deleting account:", error);
    }
  };

  const handleUpdateProfile = async () => {
    try {
      const response = await axios.patch(
        `${API_BASE_URL}/user/update-profile/${userId}`,
        updateDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data); // Handle success
      toggleEditMode();
    } catch (error) {
      console.error("Error updating profile:", error.message);
      // Handle error
    }
  };

  const currentTheme = useSelector((state) => state.theme);
  const actionsCSS = `py-2 px-8 text-center cursor-pointer rounded-md hover:underline`;

  // const getProfilePicture = async () => {
  //   try {
  //     // Assuming you have a unique identifier for the user's profile picture
  //     // const pictureRef = ref(storage, `images/${userId}/profilePicture`);
  //     const pictureRef = ref(storage, `images/${userId}/profilePicture`);
  //     const pictureURL = await getDownloadURL(pictureRef);

  //     dispatch(setUserProfilePicture(pictureURL));
  //   } catch (error) {
  //     console.error("Error fetching profile picture:", error);
  //   }
  // };

  // useEffect(() => {
  //   getProfilePicture();
  // }, [userId, storage]);

  const inputBoxStyle = `${themes[currentTheme].colors.input} ${themes[currentTheme].colors.inputText} mt-1 p-2 w-full rounded-md focus:outline-none`;

  return (
    <div
      className={`h-screen overflow-y-auto relative p-4 ${themes[currentTheme].colors.pageBackground}`}
    >
      <h1
        className={`text-3xl font-semibold mb-6 ${themes[currentTheme].colors.text}`}
      >
        User Profile
      </h1>
      {showPasswordForm && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="container mx-auto">
            <ChangePassword
              onSuccess={() => {
                setShowPasswordForm(false);
              }}
            />
          </div>
        </div>
      )}
      {/* {showThemes && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="container mx-auto">
            <ChangeTheme
              onSuccess={() => {
                setShowThemes(false);
              }}
            />
          </div>
        </div>
      )} */}
      {loading ? (
        <div
          className={`flex justify-center items-center flex-col h-96 ${themes[currentTheme].colors.accentText}`}
        >
          <div className="h-8">
            <PropagateLoader
              color={`${themes[currentTheme].colors.loader}`}
              size={15}
              margin={2}
            />
          </div>
          <p className="text-center">
            Securing your profile data, we're fetching it now.
            <br />
            Your privacy is our priority!
          </p>
        </div>
      ) : (
        <div className="flex flex-col">
          <div
            className={`grid grid-cols-1  xl:grid-cols-3 gap-2 rounded-lg ${themes[currentTheme].colors.card} shadow-md p-4`}
          >
            <div className="p-2 flex flex-col justify-center items-center">
              {userProfilePicture.userProfilePicture ? (
                <img
                  src={userProfilePicture.userProfilePicture}
                  alt="Profile"
                  className={`w-48 h-48 rounded-full object-cover hover:scale-105 transition-all duration-300 mb-4`}
                  whileHover={{ scale: 1.05 }}
                />
              ) : (
                <div className="flex items-center justify-center text-9xl w-48 h-48 rounded-full border-2 hover:scale-105 transition-all duration-700 mb-4">
                  {username?.charAt(0)}
                </div>
              )}
              <p className=" text-4xl">{username}</p>
              <div className="flex flex-col items-center">
                {file && (
                  <>
                    <div
                      className={`${themes[currentTheme].colors.accentText} mt-2 flex items-center`}
                    >
                      <TiTick fontSize={40} />
                      <span className="">
                        Selected File:
                        <br />
                        {file.name}
                      </span>
                    </div>
                    <button
                      className={`${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonText} px-4 py-2 rounded-md`}
                      onClick={handleUpload}
                    >
                      {userProfilePicture.userProfilePicture
                        ? "Change"
                        : "Upload"}
                    </button>
                  </>
                )}
              </div>
              {uploadProgress > 0 && (
                <div className="mt-4 w-full">
                  <div className="flex items-center">
                    <div className="flex-1 bg-gray-200 rounded-full overflow-hidden">
                      <div
                        className={`h-2 ${themes[currentTheme].colors.button}`}
                        style={{ width: `${uploadProgress}%` }}
                      ></div>
                    </div>
                    <div
                      className={`ml-2 ${themes[currentTheme].colors.accentText}`}
                    >
                      {uploadProgress}%
                    </div>
                  </div>
                </div>
              )}

              {/* Confirmation message */}
              {showConfirmation && (
                <div
                  className={`mt-4 ${themes[currentTheme].colors.accentText} py-2 px-4 rounded-md`}
                >
                  Your Avatar is updated!
                </div>
              )}
            </div>

            <div className="p-2 border-l flex flex-col justify-center">
              <div
                className="flex justify-end cursor-pointer"
                onClick={toggleEditMode}
              >
                <MdEdit fontSize={22} />
              </div>

              {editMode ? (
                // Input fields for editable details
                <div className="flex flex-col gap-1 justify-between">
                  <input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={editMode ? updateDetails.firstName : user?.firstName}
                    onChange={handleInputChange}
                    className={inputBoxStyle}
                  />

                  <input
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={editMode ? updateDetails.lastName : user?.lastName}
                    onChange={handleInputChange}
                    className={inputBoxStyle}
                  />
                  <p className={`font-semibold`}>{email}</p>
                  <input
                    type="date"
                    name="dateOfBirth"
                    placeholder="Date of Birth"
                    value={
                      editMode ? updateDetails.dateOfBirth : user?.dateOfBirth
                    }
                    onChange={handleInputChange}
                    className={inputBoxStyle}
                  />

                  <input
                    type="tel"
                    name="mobileNumber"
                    placeholder="Mobile Number"
                    value={
                      editMode ? updateDetails.mobileNumber : user?.mobileNumber
                    }
                    onChange={handleInputChange}
                    className={inputBoxStyle}
                  />

                  <input
                    type="text"
                    name="country"
                    placeholder="Country"
                    value={editMode ? updateDetails.country : user?.country}
                    onChange={handleInputChange}
                    className={inputBoxStyle}
                  />

                  <input
                    type="number"
                    name="income"
                    placeholder="Monthly Income"
                    value={editMode ? updateDetails.income : user?.income}
                    onChange={handleInputChange}
                    className={inputBoxStyle}
                  />
                  <p className={`font-semibold`}>
                    Member since {user && formatDate(user.memberSince)}
                  </p>

                  <div className="flex justify-center gap-8">
                    <button
                      className={`p-2 px-4 text-center cursor-pointer rounded-md ${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonText} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHoverText}`}
                      onClick={handleUpdateProfile}
                    >
                      Update Profile
                    </button>

                    <button
                      className={`p-2 px-4 text-center cursor-pointer rounded-md ${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonText} ${themes[currentTheme].colors.errorHover} ${themes[currentTheme].colors.buttonHoverText}`}
                      onClick={() => {
                        setEditMode(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col gap-4 justify-between">
                  <p className={`text-lg font-semibold`}>
                    {user?.firstName} {user?.lastName}
                  </p>
                  <p className={`font-semibold`}>{email}</p>
                  <p className={`font-semibold`}>{user?.mobileNumber}</p>
                  <p className={`font-semibold`}>{user?.country}</p>
                  <p className={`font-semibold`}>
                    {formatDate(user?.dateOfBirth)}
                  </p>
                  <p className={`font-semibold`}>₹ {user?.income}</p>
                  <p className={`font-semibold`}>
                    Member since {user && formatDate(user.memberSince)}
                  </p>
                </div>
              )}
            </div>

            <div className="p-2 border-l flex flex-col justify-center">
              <div className="flex flex-col">
                <label htmlFor="fileInput" className={actionsCSS}>
                  {userProfilePicture.userProfilePicture
                    ? "Change Avatar"
                    : "Upload Avatar"}
                </label>

                <input
                  type="file"
                  id="fileInput"
                  onChange={handleFileChange}
                  className="hidden"
                />

                {userProfilePicture.userProfilePicture && (
                  <button className={actionsCSS} onClick={deleteAvatar}>
                    Delete Avatar
                  </button>
                )}

                <button
                  className={actionsCSS}
                  onClick={() => {
                    setShowPasswordForm(true);
                  }}
                >
                  Change Password
                </button>
                {/* <button
                  className={actionsCSS}
                  onClick={() => {
                    setShowThemes(true);
                  }}
                >
                  Change Theme
                </button> */}

                <button className={actionsCSS} onClick={handleDownloadData}>
                  Download Data
                </button>

                <button
                  className={actionsCSS}
                  onClick={() => {
                    setShowConfirmDelete(true);
                  }}
                >
                  Delete Account
                </button>

                <button className={actionsCSS}>
                  <a href={`mailto:hello@sashankgl.xyz`}>Contact us</a>
                </button>
              </div>
            </div>
          </div>

          {showConfirmDelete && (
            <div
              className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50`}
            >
              <div
                className={`${themes[currentTheme].colors.innerBackground} rounded-lg p-4 m-12`}
              >
                <div className="text-2xl">Are you sure?</div>
                <div className="my-3">
                  This will permanently delete your account with us and all your
                  loan data
                </div>
                <div className="flex flex-col sm:flex-row gap-3">
                  <button
                    className={`${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonText} ${themes[currentTheme].colors.buttonHoverText} font-semibold py-2 px-4 rounded-md  focus:outline-none`}
                  >
                    Download Data
                  </button>
                  <button
                    className={`${themes[currentTheme].colors.button} ${themes[currentTheme].colors.errorHover} ${themes[currentTheme].colors.buttonText} ${themes[currentTheme].colors.buttonHoverText} font-semibold py-2 px-4 rounded-md  focus:outline-none`}
                    onClick={handleDeleteAccount}
                  >
                    Yes, Delete
                  </button>
                  <button
                    className={`${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonText} ${themes[currentTheme].colors.buttonHoverText} font-semibold py-2 px-4 rounded-md  focus:outline-none`}
                    onClick={() => setShowConfirmDelete(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}

          {userLoanData && (
            <div
              className={`flex flex-col gap-2 rounded-lg ${themes[currentTheme].colors.card} shadow-md mt-4 p-4`}
            >
              <div>
                Active Loans:{" "}
                <span
                  className={`${themes[currentTheme].colors.accentText} font-semibold`}
                >
                  {userLoanData.numberOfActiveLoans}
                </span>
              </div>
              <div>
                Total Loans:{" "}
                <span
                  className={`${themes[currentTheme].colors.accentText} font-semibold`}
                >
                  {userLoanData.numberOfLoans}
                </span>
              </div>
              <div>
                Total Loan Amount:{" "}
                <span
                  className={`${themes[currentTheme].colors.accentText} font-semibold`}
                >
                  ₹ {userLoanData.totalLoanAmount}
                </span>
              </div>
              <div>
                Total Outstanding:{" "}
                <span
                  className={`${themes[currentTheme].colors.accentText} font-semibold`}
                >
                  ₹ {userLoanData.totalOutstandingBalance}
                </span>
              </div>
            </div>
          )}

          {deletedMessage && (
            <div
              className={`fixed inset-0 flex flex-col items-center justify-center text-3xl text-center p-12 ${themes[currentTheme].colors.sidenavBackground}`}
            >
              <p>Your account has been successfully deleted.</p>
              <p>Logging you out.</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UserProfile;
