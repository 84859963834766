import React, { useState } from "react";
import axios from "axios";
import { registerAPI } from "../utils/ListOfAPIs";

import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { FaUserAlt, FaEnvelope, FaLock } from "react-icons/fa";
import { useSelector } from "react-redux";
import themes from "../utils/themes";
import { PropagateLoader } from "react-spinners";

const Register = () => {
  const initialFormData = {
    username: "",
    email: "",
    password: "",
    userId: "",
  };
  const [isLoading, setIsLoading] = useState(null);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState(initialFormData);
  const { login } = useAuth();
  const [registerMessage, setRegisterMessage] = useState("");
  const navigate = useNavigate();
  const [showPassReq, setShowPassReq] = useState(false);
  const [showUserReq, setShowUserReq] = useState(false);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const currentTheme = useSelector((state) => state.theme);
  const isStrongPassword = (password) => {
    // Check for at least 8 characters, uppercase, lowercase, numbers, and special characters
    const strongPasswordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return strongPasswordRegex.test(password);
  };
  const handleRegister = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(registerAPI, formData);
      console.log("Server Response:", response.data);
      if (response.status === 200) {
        const { token, email, username, userId } = response.data;
        login(token, email, username, userId);
        setRegisterMessage(`${username}`);

        setIsLoading(false);

        setTimeout(() => {
          setFormData(initialFormData);
          navigate(`/verify/${userId}`);
        }, 6000);
      } else {
        setIsLoading(false);
        setRegisterMessage(`Registration failed. ${response.data.error}`);
      }
    } catch (error) {
      setError(`${error.response.data.error}`);
      console.error("Error registering user:", error.message);
      setIsLoading(false);
    }
  };

  const inputBoxStyle = `${themes[currentTheme].colors.input} ${themes[currentTheme].colors.inputText} p-2 w-full rounded-lg focus:outline-none`;

  return (
    <div
      className={`flex flex-col items-center justify-center h-screen w-screen ${themes[currentTheme].colors.card}`}
    >
      <div
        className={`${themes[currentTheme].colors.innerBackground} p-8 rounded-lg shadow-md w-96`}
      >
        {/* <h2>Join us to take control of your loans. Here's what you can expect!</h2> */}
        <h2 className="text-2xl font-bold mb-6 text-center">Join Us</h2>

        <div className="mb-4">
          <div
            className={`flex items-center ${themes[currentTheme].colors.input} rounded-lg`}
          >
            <FaUserAlt className="w-12" />
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              className={inputBoxStyle}
              placeholder="Display Name"
              onFocus={() => setShowUserReq(true)}
              onBlur={() => setShowUserReq(false)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleRegister();
                }
              }}
            />
          </div>
        </div>
        {showUserReq && (
          <div
            className={`text-center ${themes[currentTheme].colors.textLight} rounded-lg mb-4`}
          >
            Choose a username with letters and spaces, excluding other
            characters.
          </div>
        )}
        <div className="mb-4">
          <div
            className={`flex items-center ${themes[currentTheme].colors.input} rounded-lg `}
          >
            <FaEnvelope className="w-12" />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={inputBoxStyle}
              placeholder="Email Address"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleRegister();
                }
              }}
            />
          </div>
        </div>
        <div className="mb-4">
          <div
            className={`flex items-center ${themes[currentTheme].colors.input} rounded-lg`}
          >
            <FaLock className="w-12" />
            <input
              type="password"
              name="password"
              value={formData.password}
              placeholder="Strong Password"
              onChange={handleChange}
              className={inputBoxStyle}
              onFocus={() => setShowPassReq(true)}
              onBlur={() => setShowPassReq(false)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleRegister();
                }
              }}
            />
          </div>
        </div>
        {!isStrongPassword(formData.password) && showPassReq && (
          <div
            className={`text-center ${themes[currentTheme].colors.textLight} rounded-lg mb-4`}
          >
            Ensure your password is 8 characters long and includes a mix of
            uppercase, lowercase, numbers, and special characters.
          </div>
        )}
        <button
          onClick={handleRegister}
          className={`w-full focus:outline-none ${themes[currentTheme].colors.button} py-2 rounded-lg ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonText} transition duration-300 mb-2`}
        >
          {isLoading ? (
            <p className="animate-pulse">Registering...</p>
          ) : (
            <p>Register</p>
          )}
        </button>

        <div className="py-2">
          Already part of our community?{" "}
          <Link
            to="/login"
            className={`${themes[currentTheme].colors.accentText} font-semibold`}
          >
            Log in here
          </Link>
        </div>
        {/* <div>By signing up, you agree to our Terms and Conditions.</div> */}

        {error && (
          <div
            className={`${themes[currentTheme].colors.errorText} p-2 rounded-lg text-center`}
          >
            {error}
          </div>
        )}
      </div>

      {registerMessage && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div
            className={`${themes[currentTheme].colors.innerBackground} p-6 px-12 rounded-lg shadow-md flex flex-col items-center justify-center`}
          >
            <p className="text-center text-3xl">
              Cheers{" "}
              <span className={`${themes[currentTheme].colors.accentText}`}>
                {registerMessage}
              </span>
              !<br />
              <p className="mt-3 mb-2 text-base">
                Welcome to the vibrant Loan Management Community. We're thrilled
                to have you.
              </p>
            </p>

            <div className="p-2 rounded-lg text-center transition duration-300">
              A verification email on its way! Please check your inbox.
            </div>
            <div
              className={`${themes[currentTheme].colors.accentText} mt-2 animate-pulse rounded-lg text-center transition duration-300`}
            >
              Redirecting to Verification Page . . .
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Register;
