import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  verifyCodeAPI,
  reverifyAPI,
  resendCodeOnRequestAPI,
} from "../utils/ListOfAPIs";
import { Link, useParams, useNavigate } from "react-router-dom";
import Logo from "../assets/Logo.png";
import { useAuth } from "../contexts/AuthContext";
import { useSelector } from "react-redux";
import themes from "../utils/themes";
import { API_BASE_URL } from "../utils/apiConfig";

const VerifyFromLogin = () => {
  const [verified, setVerified] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(30);
  const [intervalId, setIntervalId] = useState(null);
  const [showCodeSentMessage, setShowCodeSentMessage] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [passedEmail, setPassedEmail] = useState("");
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);
  const { email } = useAuth();
  const { userId } = useParams();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const code = e.target.value.replace(/[^0-9]/g, "");
    if (code.length <= 6) {
      setVerificationCode(code);
    } else {
      setVerificationCode(code.slice(0, 6));
    }
  };

  const handleEmailChange = (e) => {
    setPassedEmail(e.target.value);
    console.log("Email PAssed: ", e.target.value);
  };

  const handleSubmitEmail = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResendDisabled(true);

    try {
      const response = await axios.post(`${API_BASE_URL}/user/code-request`, {
        passedEmail: passedEmail,
      });

      if (response.data.message === "New verification code sent successfully") {
        startResendTimer();
        setIsCodeSent(true);
        setShowCodeSentMessage(true);
        setVerificationEmailSent(true);

        setTimeout(() => {
          setShowCodeSentMessage(false);
        }, 5000);

        console.log(response.data);
      } else {
        console.error(
          "Error resending verification code:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Error resending verification code:", error);
      setErrorMessage(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitCode = async () => {
    const code = verificationCode;

    try {
      const response = await axios.post(verifyCodeAPI, { code });

      if (
        response.data.message === "Email verified successfully! Please login"
      ) {
        setVerified(true);
      } else {
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      console.error(error.response.data.message);
      setErrorMessage(error.response.data.message);
    }
  };

  const handleResendCode = async () => {
    console.log("Start sending...");
    setIsLoading(true);
    setResendDisabled(true);
    try {
      const response = await axios.post(`${resendCodeOnRequestAPI}/${userId}`);
      if (response.data.message === "New verification code sent successfully") {
        startResendTimer();
        setIsCodeSent(true);
        setShowCodeSentMessage(true);

        setTimeout(() => {
          setShowCodeSentMessage(false);
        }, 5000);

        console.log(response.data);
      } else {
        console.error(
          "Error resending verification code:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Error resending verification code:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const startResendTimer = () => {
    const id = setInterval(() => {
      setResendTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(id);
          setResendDisabled(false);
          return 30;
        } else {
          return prevTimer - 1;
        }
      });
    }, 1000);

    setIntervalId(id);
  };

  useEffect(() => {
    startResendTimer();
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  const currentTheme = useSelector((state) => state.theme);
  const inputBoxStyle = `${themes[currentTheme].colors.input} ${themes[currentTheme].colors.inputText} mt-1 p-2 w-full rounded-md focus:outline-none focus:border-blue-500`;
  return (
    <div
      className={`min-h-screen flex flex-col gap-4 items-center justify-center px-4 py-16 ${themes[currentTheme].colors.pageBackground}`}
    >
      <div className="flex justify-center gap-2 items-center">
        <div>
          <img className="w-16" src={Logo} alt="logo" />
        </div>
        <div
          className={`text-3xl font-semibold ${themes[currentTheme].colors.accentText}`}
        >
          LoanHub
        </div>
      </div>
      <div className=" text-center">
        You are one step away from accessing your account.
      </div>

      <div
        className={`${themes[currentTheme].colors.card} p-8 rounded shadow-md w-96`}
      >
        {verified ? (
          <div>
            <p
              className={`${themes[currentTheme].colors.accentText} font-semibold text-lg text-center`}
            >
              Email verified successfully!
            </p>
            <div
              className={`${themes[currentTheme].colors.accentText} mt-5 animate-pulse rounded-lg text-center transition duration-300`}
            >
              Redirecting to Login Page...
            </div>
          </div>
        ) : (
          <>
            <div className="space-y-4">
              {verificationEmailSent ? (
                <div className="flex flex-col justify-center space-x-2">
                  <h2 className="text-2xl font-semibold text-center mb-4">
                    Verify Your Email
                  </h2>
                  <input
                    type="text"
                    onChange={handleChange}
                    value={verificationCode}
                    className={`${themes[currentTheme].colors.input} ${themes[currentTheme].colors.inputText} text-5xl text-center p-2 w-full rounded-md focus:outline-none tracking-[.35em]`}
                  />
                  <div
                    onClick={handleSubmitCode}
                    className={`w-full text-center cursor-pointer ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonText}py-3 rounded `}
                  >
                    Verify
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center gap-4">
                  <h2 className="text-2xl font-semibold text-center mb-4">
                    Enter Your Email
                  </h2>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={handleEmailChange}
                    className={inputBoxStyle}
                    placeholder="Email"
                  />
                  <button
                    onClick={handleSubmitEmail}
                    className={`w-full ${themes[currentTheme].colors.button} ${themes[currentTheme].colors.buttonHover} ${themes[currentTheme].colors.buttonText} py-2 rounded-lg`}
                  >
                    Confirm
                  </button>
                </div>
              )}

              <p className="mt-2 text-center ">
                <p className="">Not received your code?</p>
                {isLoading ? (
                  <span className={`${themes[currentTheme].colors.textLight}`}>
                    Sending...
                  </span>
                ) : (
                  <>
                    {resendDisabled ? (
                      <span className="">
                        Resend code in {resendTimer} seconds
                      </span>
                    ) : (
                      <>
                        <button
                          type="button"
                          onClick={handleResendCode}
                          className={`${themes[currentTheme].colors.accentText} hover:text-lg transition-all duration-200 h-8  underline cursor-pointer`}
                        >
                          Resend Code
                        </button>
                      </>
                    )}
                  </>
                )}
              </p>
              {isCodeSent && showCodeSentMessage && (
                <p className="text-center">
                  New verification code sent successfully to {email}
                </p>
              )}
              {errorMessage && (
                <p
                  className={`text-center ${themes[currentTheme].colors.errorText}`}
                >
                  {errorMessage}
                </p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default VerifyFromLogin;
