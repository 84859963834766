const initialState = {
  theme: localStorage.getItem("selectedTheme") || "harmony",
};


const themeReducer = (state = initialState.theme, action) => {
  switch (action.type) {
    case "SET_THEME":
      return action.payload;
    default:
      return state;
  }
};

export default themeReducer;
